.header {
    padding: 20px 0;
    background: #ffffff;

    &--shadow {
        box-shadow: 0 8px 10px -3px rgba(47, 52, 78, 0.03);
    }

    &__wrap {
        padding: 0 50px;

        @media (max-width: 767px) {
            padding: 0 15px;
        }
    }

    &__logo-text {
        font-weight: 600;
        font-size: 12px;
        margin-left: 12px;
        color: #90949d;
    }

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__logo {
        display: flex;
        align-items: center;

        img {
            @media (max-width: 767px) {
                max-width: 190px;
            }
        }
    }

    &__hamburger {
        display: none;
        background: transparent;
        border: none;

        @media (max-width: 767px) {
            display: block;
        }
    }
}

.main-nav {
    @media (max-width: 767px) {
        display: none;
    }

    &__list {
        display: flex;
        list-style-type: none;
    }

    &__item {
        margin-right: 45px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        font-weight: 600;
        font-size: 14px;
        color: #4a4a4a;

        &.active {
            color: $color-blue;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                height: 2px;
                background: $color-blue;
                bottom: -30px;
            }
        }
        &:focus {
            color: $color-blue;
            text-decoration: underline;
        }
        &:hover {
            color: $color-blue;
        }
    }
}

.main-search {
    .container {
        @media (max-width: 767px) {
            padding: 0;
        }
    }

    &__content {
        padding: 120px 0 228px;
        background: url(../images/bg-color.jpg) center / cover no-repeat;
        border-radius: 15px;

        @media (max-width: 767px) {
            padding: 40px 0 90px;
        }
    }

    &__ttl {
        text-align: center;
        font-weight: 700;
        font-size: 38px;
        //color: #fff;
        margin-bottom: 50px;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 30px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__btn_viewall {
        text-align: center;
        margin-top: 10px;
    }

    &__search {
        max-width: 554px;
        margin: 0 auto;

        @media (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__intro {
        margin: auto;
        text-align: center;
        padding: 10px;
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        line-height: 24px;
    }
}

.recent-updates {
    margin: -110px 0 100px;

    @media (max-width: 767px) {
        margin: -40px 0 60px;

        .container {
            padding: 0;
        }
    }

    &__box {
        max-width: 1077px;
        width: 100%;
        margin: 0 auto;
        border-radius: 25px 25px 0 0;
        background: #fff;
        padding: 60px 30px 0;

        @media (max-width: 767px) {
            padding: 40px 15px;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 28px;
        text-align: center;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
    }

    &__list {
        display: flex;
        margin: 0 -12px -20px;
        width: calc(100% + 24px);
        flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;
    }

    &__item {
        padding: 0 12px;
        width: calc(100% / 3);
        margin-bottom: 20px;

        @media (max-width: 992px) {
            width: 50%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.recent-news {
    background: #fff;
    border: 1px solid #feffff;
    box-shadow: 0 10px 23px -3px rgba(47, 52, 78, 0.09);
    border-radius: 15px;
    padding: 30px 25px;
    display: block;
    transition: box-shadow 0.2s ease-in-out;
    height: 300px;

    &:focus {
        box-shadow: 12px 34px 67px -5px rgba(47, 52, 78, 0.2);
    }

    &:hover {
        box-shadow: 12px 34px 67px -5px rgba(47, 52, 78, 0.09);
    }

    @media (max-width: 767px) {
        border: 1px solid #dbe7ea;
        box-shadow: none;
        padding: 30px 15px;

        &:hover {
            box-shadow: none;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.3;
        color: $text-color;
        height: 55px;
    }

    &__text {
        font-size: 14px;
        color: #5e6877;
        line-height: 1.7;
        margin-bottom: 15px;
        height: 160px;
        inline-size: 280px;
        overflow-wrap: break-word;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__date {
        font-weight: 600;
        font-size: 14px;
        color: #5e6877;
    }

    &__link {
        font-size: 14px;
        color: #1676a7;
        position: relative;
        padding-right: 18px;
        font-weight: 600;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background: url("../images/ico-link-arrow-right.svg") center / contain no-repeat;
        }
    }
    &__link:focus {
        text-decoration: underline;
    }
}

.footer {
    &__row {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e7e9ee;
        padding: 39px 0;
        align-items: center;

        @media (max-width: 992px) {
            flex-direction: column;
        }
    }

    .ack {
        font-size: 10px;
        color: #5e6877;
        letter-spacing: 0;
        line-height: 12px;
        margin-left: 40px;
        margin-right: 30px;
    }

    .ack_link {
        text-decoration: underline;
        color: #5e6877;
    }

    &__logo {
        @media (max-width: 992px) {
            margin-bottom: 60px;
        }

        > a img {
            max-width: 150px;
            max-height: 40px;
        }
    }

    &__partners {
        display: flex;
        list-style-type: none;

        li {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            > a img {
                max-width: 150px;
                max-height: 40px;
            }
        }
    }
}

.footer-nav {
    @media (max-width: 992px) {
        margin-bottom: 60px;
    }

    &__list {
        display: flex;
        list-style-type: none;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__item {
        margin-right: 45px;

        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }

        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 40px;
        }
    }

    &__link {
        font-weight: 600;
        font-size: 14px;
        color: #4a4a4a;
        letter-spacing: 0;

        &.active {
            color: $color-blue;
        }

        &:hover {
            color: $color-blue;
        }
    }
}

.maternal-conditions {
    padding-bottom: 100px;
    &:focus {
        // box-shadow: 4px 4px 2px #195a7c;
        box-shadow: 6px 6px 10px rgba(47, 52, 78, 0.2);
      }
    @media (max-width: 767px) {
        padding-bottom: 0;

        .container {
            padding: 0;
        }
    }

    &__box {
        position: relative;
        z-index: 1;
        padding-top: 60px;

        @media (max-width: 767px) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &::before {
            position: absolute;
            z-index: -1;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            border-radius: 15px;
            opacity: 0.8;
            height: 322px;
            background-image: linear-gradient(150deg, #a1d7f3 30%, #a1d7f3 80%);

            @media (max-width: 767px) {
                height: auto;
                bottom: 115px;
            }
        }
    }

    &__ttl {
        text-align: center;
        font-weight: 70;
        font-size: 28px;
        //color: #ffffff;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            padding: 0 15px;
            font-size: 24px;
            margin-bottom: 30px;
        }
    }

    &__list {
        padding: 0 40px;
        margin: 0 -12px -20px;
        width: calc(100% + 24px);
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        list-style-type: none;

        @media (max-width: 767px) {
            padding: 0 15px;
        }
    }

    &__item {
        width: 25%;
        padding: 0 12px;
        margin-bottom: 20px;

        @media (max-width: 992px) {
            width: 50%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__btn {
        text-align: center;

        @media (max-width: 767px) {
            padding: 0 15px;
        }

        .ui.button {
            width: 185px;
            margin: 0;

            @media (max-width: 767px) {
                width: 100%;
                background: #fff;
                color: #2c3647;
            }
        }
    }
}

.maternal-news {
    background: #fff;
    box-shadow: 0 10px 23px -4px rgba(47, 52, 78, 0.08);
    border-radius: 15px;
    padding: 25px 25px 30px;
    display: block;
    transition: box-shadow 0.2s ease-in-out;
    height: 275px;
    &:focus {
        box-shadow: 12px 34px 67px -5px rgba(47, 52, 78, 0.2);
    }
    &:hover {
        box-shadow: 12px 34px 67px -5px rgba(47, 52, 78, 0.09);
    }

    &__tag {
        margin-bottom: 24px;
    }

    &__ttl {
        font-weight: bold;
        font-size: 16px;
        color: #303b4d;
        line-height: 24px;
        margin-bottom: 18px;
        height: 45px;
    }

    &__text {
        font-size: 14px;
        color: #5e6877;
        line-height: 1.7;
        margin-bottom: 10px;
        height: 145px;
        overflow: hidden;
        inline-size: 230px;
        overflow-wrap: break-word;
    }

    &__link {
        font-size: 14px;
        color: $color-blue;
        position: relative;
        padding-right: 18px;
        font-weight: 600;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background: url("../images/ico-link-arrow-right.svg") center / contain no-repeat;
        }
    }
}

.in-focus {
    padding: 0 0 0;
    margin-bottom: 100px;

    @media (max-width: 767px) {
        margin-bottom: 60px;
    }

    &__ttl {
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 24px);
        margin: 0 -12px;
    }

    &__left {
        width: 50%;
        height: inherit;
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 992px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    &__right {
        width: 50%;
        padding: 0 12px;
        height: inherit;

        @media (max-width: 992px) {
            width: 100%;
        }
    }

    &__list {
        list-style-type: none;
    }

    &__item {
        margin-bottom: 20px;
        height: 176px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.focus-news {
    display: flex;
    background: #ffffff;
    box-shadow: 0 10px 23px -4px rgba(47, 52, 78, 0.08);
    border-radius: 15px;
    padding: 20px 25px 20px 20px;
    $c: &;
    justify-content: space-between;

    &:focus {
        box-shadow: 12px 34px 67px -5px rgba(47, 52, 78, 0.2);
    }
    @media (max-width: 767px) {
        box-shadow: none;
        border: 1px solid #dbe7ea;
        padding: 20px 15px;
    }

    &__img {
        width: 166px;
        height: 100%;
        border-radius: 10px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        object-fit: cover;

        @media (max-width: 767px) {
            width: 120px;
        }
    }

    &__info {
        width: calc(100% - 166px);
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: inherit;

        @media (max-width: 767px) {
            width: calc(100% - 120px);
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        line-height: 24px;
        margin-bottom: 8px;
    }

    &__text {
        font-size: 14px;
        color: #5e6877;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 20px;
        height: 72px;

        @media (max-width: 767px) {
            display: contents;
        }
    }

    &__text2 {
        font-size: 14px;
        color: #5e6877;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 20px;
        height: 48px;

        @media (max-width: 767px) {
            display: contents;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__date {
        font-weight: 600;
        font-size: 14px;
        color: #5e6877;
    }

    &__link {
        font-size: 14px;
        color: $color-blue;
        position: relative;
        padding-right: 18px;
        font-weight: 600;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background: url("../images/ico-link-arrow-right.svg") center / contain no-repeat;
        }
    }
    &__link:focus {
        text-decoration: underline;
    }

    &--big {
        flex-direction: column;
        padding-top: 25px;
        padding-bottom: 30px;
        height: 570px;

        #{$c}__img {
            width: 100%;
            height: 270px;
            margin-bottom: 20px;
            object-fit: cover;

            &::after {
                content: "";
                display: block;
                padding-bottom: 54%;
            }
        }

        #{$c}__info {
            padding-left: 0;
            width: 100%;
        }

        #{$c}__ttl {
            margin-bottom: 19px;
        }

        #{$c}__text {
            margin-bottom: 28px;
        }
    }
}

.search-result {
    &__box {
        width: 100%;
        max-width: 712px;
        margin: 0 auto;
    }

    &__ttl {
        margin: 60px 0 50px;
        font-weight: 700;
        font-size: 42px;
        color: #2c3647;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
    }

    &__search-bar {
        margin-bottom: 100px;

        @media (max-width: 767px) {
            margin-bottom: 60px;
        }
    }

    &__pagination {
        margin-bottom: 100px;
    }
}

.search-item {
    border-bottom: 1px solid #e7e9ee;
    margin-bottom: 40px;
    list-style-type: none;

    &:last-child {
        border-bottom: none;
    }

    &__ttl {
        font-weight: 70;
        font-size: 18px;
        color: $color-blue;
        line-height: 1.6;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__text {
        font-size: 16px;
        color: #5e6877;
        letter-spacing: 0;
        line-height: 1.5;
        margin-bottom: 21px;
    }

    &__footer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__keywords {
        font-size: 14px;
        color: #90949d;
    }

    .label {
        margin-left: 12px;
    }
}

.search-item-ul {
    list-style-type: none;
}

.breadcrumbs {
    margin-top: 25px;
}

.page-navigation {
    position: sticky;
    top: 30px;

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        line-height: 1.7;
        border-bottom: 1px solid #e7e9ee;
        padding-bottom: 20px;
        margin-bottom: 29px;

        @media (max-width: 992px) {
            margin-bottom: 15px;
            padding-bottom: 10px;
        }
    }

    &__list {
        list-style-type: none;

        @media (max-width: 992px) {
            display: flex;
        }
    }

    &__item {
        margin-bottom: 33px;

        &:last-child {
            margin-bottom: 0;
            margin-right: 0;
        }

        @media (max-width: 992px) {
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    &__link {
        position: relative;
        padding-left: 20px;
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;

        &:hover {
            color: $color-blue;
        }

        @media (max-width: 992px) {
            padding-left: 0;
            color: $color-blue;
        }

        &--active {
            color: $color-blue;
            position: relative;
            display: block;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: $color-blue;
                border-radius: 1px;
                width: 2px;
                height: 30px;
            }

            @media (max-width: 992px) {
                color: $color-blue;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.detail-page {
    margin-top: 60px;
    padding-bottom: 150px;

    .detail-page__tr-text {
        margin-top: 30px;
        font-size: 16px;
        color: #2c3647;
        line-height: 1.5;
    }

    @media (max-width: 767px) {
        padding-bottom: 100px;
    }

    &__header {
        margin: 0 34% 0 0;
        padding-bottom: 60px;

        @media (max-width: 992px) {
            margin: 0 10%;
        }

        @media (max-width: 767px) {
            margin: 0;
            padding-bottom: 80px;
        }
    }

    &__ttl {
        font-weight: 700;
        font-size: 42px;
        color: #2c3647;
        margin-bottom: 50px;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
    }

    &__body {
        display: flex;

        @media (max-width: 992px) {
            flex-wrap: wrap;
        }
    }

    &__col1 {
        width: 15%;

        @media (max-width: 992px) {
            width: 100%;
            margin-bottom: 40px;
            order: 1;
        }
    }

    &__col2 {
        width: 65%;
        margin: 0 9% 0 0;

        @media (max-width: 992px) {
            width: 55%;
            margin: 0 5% 0 0;
            order: 2;
        }

        @media (max-width: 767px) {
            width: 100%;
            margin: 0;
            order: 2;
        }
    }

    &__col3 {
        width: 25%;

        @media (max-width: 992px) {
            width: 40%;
            order: 3;
        }

        @media (max-width: 767px) {
            width: 100%;
            margin: 0;
            order: 3;
        }
    }
}

.content-block {
    padding-bottom: 25px;
    border-bottom: 1px solid #e7e9ee;
    margin-bottom: 60px;

    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        margin-top: 100px;

        @media (max-width: 767px) {
            margin-bottom: 60px;
        }
    }

    @media (max-width: 767px) {
        margin-bottom: 40px;
    }

    &__link {
        margin-bottom: 80px;
        list-style-type: none;

        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                position: relative;
                font-size: 16px;
                color: #2c3647;
                line-height: 1.5;
                position: relative;
                padding-left: 30px;
                display: inline-block;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    background: url("../images/ico-link.svg") center / contain no-repeat;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                }

                &:hover {
                    color: $color-blue;
                }
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        line-height: 1.7;
    }

    &__body {
        font-size: 16px;
        color: #2c3647;
        line-height: 1.5;

        p {
            margin-bottom: 26px;
        }

        a {
            color: $color-blue;
            text-decoration: underline;
        }
    }

    &__footer {
        font-size: 16px;
        color: #90949d;
        line-height: 1.5;
    }

    &__published {
        margin-top: 27px;
        font-size: 14px;
        color: #90949d;
        text-align: left;

        b {
            font-weight: 600;
        }
    }

    &__sub-ttl {
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        margin-bottom: 6px;
    }

    &__more {
        font-size: 14px;
        color: $color-blue;
        line-height: 1.85;
        position: relative;
        padding-right: 18px;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            width: 10px;
            height: 10px;
            background: url("../images/ico-link-arrow-right.svg") center / contain no-repeat;
        }

        &--active {
            &::after {
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }
}

.choose-translation {
    display: flex;
    align-items: center;
    background: #f6f6f6;
    border-radius: 4px;
    height: 25px;
    padding: 0 10px;
    font-size: 11px;
    color: #2c3647;

    &__ttl {
        margin-right: 3px;
    }

    &__only-current-lang {
        color: $color-blue;
        position: relative;
        margin-right: 18px;
        position: relative;
    }

    &__current-lang {
        color: $color-blue;
        position: relative;
        margin-right: 18px;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 10px;
            background: #2c3647;
            top: 50%;
            transform: translateY(-50%);
            right: -9px;
        }
    }

    &__select {
        .ui.dropdown > .text {
            color: $color-blue;
        }

        .ui.dropdown {
            padding-right: 15px;

            .menu {
                top: calc(100% + 10px);
            }
        }

        .ui.upward.dropdown > .menu {
            top: auto;
            bottom: calc(100% + 10px);
        }

        .ui.dropdown > .dropdown.icon {
            right: 0;
            position: absolute;
        }
    }
}

.api-page {
    display: flex;
    min-height: calc(100vh - 200px);

    &__delimiter {
        border-bottom: 1px dashed #d7d9dd;
        margin-bottom: 60px;
    }

    &__sidebar {
        width: 285px;
        background: #f6f6f6;
        padding-left: 5%;
        padding-top: 5%;

        @media (max-width: 992px) {
            display: none;
        }
    }

    // &__menu {
    // }

    &__sub-menu {
        margin-bottom: 12px;
        margin-left: 20px;
    }

    &__sub-item {
        margin-top: 25px;
    }

    &__item {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;

        &--highlight {
            color: #04c0bc;
        }

        &--active {
            position: relative;
            color: #04c0bc;

            &::before {
                position: absolute;
                width: 2px;
                height: 24px;
                border-radius: 1px;
                content: "";
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
                background: #04c0bc;
            }
        }
    }

    &__content {
        width: calc(100% - 285px);

        @media (max-width: 992px) {
            width: 100%;
        }
    }

    &__container {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 100px 79px;

        @media (max-width: 767px) {
            padding: 20px 15px;
        }
    }

    &__ttl {
        margin-bottom: 50px;
        font-weight: 700;
        font-size: 42px;
        color: #2c3647;
    }

    &__search-bar {
        width: 100%;
        max-width: 554px;
        margin-bottom: 100px;
    }

    &__sub-ttl {
        font-weight: 600;
        font-size: 28px;
        color: #2c3647;
        letter-spacing: 0;
        margin-bottom: 30px;

        span {
            color: #04c0bc;
        }
    }

    &__desc {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        margin-bottom: 30px;
    }

    &__table {
        width: 100%;
        margin-bottom: 60px;

        tr {
            &:nth-child(odd) {
                background: #f6f6f6;
            }

            &:last-child {
                td:first-child {
                    border-bottom-left-radius: 4px;
                }

                td:last-child {
                    border-bottom-right-radius: 4px;
                }
            }
        }

        td {
            padding: 20px 0;
            border: 1px solid #d7d9dd;

            &:nth-child(1) {
                padding-left: 25px;
                font-weight: 600;
                font-size: 16px;
                width: 236px;

                @media (max-width: 767px) {
                    width: 130px;
                }
            }

            &:nth-child(2) {
                padding-left: 25px;
                padding-right: 25px;
                font-size: 14px;
                line-height: 24px;

                span {
                    color: $color-blue;
                }
            }
        }

        th {
            padding: 20px 25px;
            font-weight: 600;
            font-size: 16px;
            color: #2c3647;
            border: 1px solid #d7d9dd;

            &:nth-child(2) {
                text-align: center;
            }
        }
    }
}

.login-page {
    min-height: 100vh;
    position: relative;
    padding-bottom: 100px;

    @media (max-width: 767px) {
        padding-bottom: 10px;
    }

    &__bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 55%;
        //background: url("../images/bg-login.jpg") center / cover no-repeat;
        background: url("../images/login-bg.jpg") center / cover no-repeat;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__header {
        padding: 22px 0;
    }

    &__body {
        padding-top: 132px;

        @media (max-width: 767px) {
            padding-top: 70px;
        }
    }

    &__form {
        padding-left: 8%;
        width: 40%;

        @media (max-width: 992px) {
            width: 50%;
            padding-left: 0;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__ttl {
        font-weight: 700;
        font-size: 28px;
        color: #2c3647;
        margin-bottom: 8px;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 12px;
        }
    }

    &__sub-ttl {
        font-size: 16px;
        color: #2c3647;
        line-height: 1.5;
        margin-bottom: 40px;

        @media (max-width: 767px) {
            font-size: 12px;
            color: #90949d;
            margin-bottom: 50px;
        }
    }

    &__group {
        margin-bottom: 19px;

        label {
            display: block;
            margin-bottom: 9px;
            font-size: 14px;
            color: #2c3647;
        }

        .ui.input {
            display: block;
            width: 100%;
        }

        .ui.input > input {
            width: 100%;
            display: block;
            height: 50px;
            padding: 0 25px;
        }
    }

    &__groupv {
        margin-bottom: 19px;
        margin-top: 19px;
        font-weight: bold;

        .ui.input {
            display: block;
            width: 100%;
        }

        .ui.input > input {
            width: 100%;
            display: block;
            height: 50px;
            padding: 0 25px;
        }
    }

    &__groupvtext {
        margin-bottom: 19px;
        margin-top: 19px;

        .ui.input {
            display: block;
            width: 100%;
        }

        .ui.input > input {
            width: 100%;
            display: block;
            height: 50px;
            padding: 0 25px;
        }
    }

    &__btn {
        margin-top: 40px;

        .ui.button {
            display: block;
            margin: 0;
            width: 100%;
        }
    }

    &__link {
        margin-top: 24px;

        @media (max-width: 767px) {
            text-align: center;
            margin-top: 50px;
        }

        a {
            font-size: 14px;
            color: $color-blue;
            letter-spacing: 0;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.mobile-menu {
    /* display: none; */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1002;
    background: #fff;

    &__wrapper {
        display: flex;
        min-height: 100%;
        align-items: center;
        justify-content: center;
        overflow: auto;
        padding: 30px 0;
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 25px;
        background: 0 0;
        border: none;
        z-index: 2;
    }

    &__list {
        margin-top: 90px;
        list-style-type: none;

        li {
            border-bottom: 1px solid #f2f2f2;

            a {
                font-weight: 600;
                font-size: 13px;
                color: #1b1b1b;
                text-transform: uppercase;
                text-align: center;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.web-page {
    padding-top: 60px;

    @media (max-width: 767px) {
        padding-top: 35px;
    }

    &__content {
        max-width: 818px;
        width: 100%;
        margin: 0 auto;
    }

    &__ttl {
        font-weight: 700;
        font-size: 42px;
        color: #2c3647;
        margin-bottom: 28px;
        padding: 0 53px;

        @media (max-width: 767px) {
            padding: 0;
            font-size: 24px;
        }
    }

    &__tr {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 16px;
        color: #2c3647;
        line-height: 1.5;
    }

    &__video {
        margin: 60px 0;
    }

    &__ttl-video {
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        color: #2c3647;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            font-size: 24px;
        }
    }

    &__sub-ttl {
        font-weight: 600;
        font-size: 28px;
        color: #2c3647;
        margin-bottom: 28px;
        padding: 0 53px;

        @media (max-width: 767px) {
            padding: 0;
            font-size: 24px;
        }
    }

    &__text {
        font-size: 16px;
        color: #2c3647;
        line-height: 30px;
        margin-bottom: 35px;
        padding: 0 53px;

        @media (max-width: 767px) {
            padding: 0;
            font-size: 16px;
            line-height: 1.7;
        }
    }

    &__modal {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__img-video {
        cursor: pointer;
        border-radius: 15px;
        position: relative;

        img {
            max-width: 100%;
            width: 100%;
        }

        &::before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: "";
            width: 70px;
            height: 70px;
            background: url(../images/ico-play.svg) center / contain no-repeat;
            box-shadow: 0 9px 18px 0 rgba(47, 52, 78, 0.08);
            border-radius: 50%;
        }
    }
}

.web-page-contact {
    padding-top: 60px;
    border-radius: 15px;
    margin-bottom: 220px;
    height: 268px;
    background-image: linear-gradient(140deg, #a1d7f3 0%, #a1d7f3 100%);

    @media (max-width: 767px) {
        padding-top: 30px;
        height: auto;
        margin-bottom: 150px;
    }

    &__ttl {
        font-weight: 500;
        font-size: 28px;
        letter-spacing: 0;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 24px;
        }
    }

    &__content {
        background: #ffffff;
        box-shadow: 0 10px 23px -4px rgba(47, 52, 78, 0.08);
        border-radius: 15px;
        max-width: 712px;
        width: 100%;
        margin: 0 auto 0;
        position: relative;
        bottom: -60px;
        padding: 30px 20px 30px 30px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }

        p {
            margin: 0 0 20px 0;
            padding: 0;
            font-size: 16px;
            color: #2c3647;
            letter-spacing: 0;
            line-height: 24px;
        }
    }

    &__left {
        width: 430px;
        padding-right: 30px;

        @media (max-width: 767px) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__sub-ttl {
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 18px;
    }

    &__link {
        font-size: 16px;
        color: #2c3647;
        letter-spacing: 0;
        line-height: 24px;

        i.icon,
        i.icons {
            color: #1676a7;
        }
    }

    &__right {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            width: 100%;
            padding-top: 15px;
        }

        img {
            max-width: 100%;
            width: 230px;
        }
    }
}

.ul-margin-left ul {
    margin-left: 18px;
}

.colaboration-edit {
    display: inline-flex;

    .ui.input {
        margin-left: 20px;
    }
}

.ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-base-border) !important;
}

.margin-top-20 {
    margin-top: 20px;
}
.display-block {
    display: block;
}
.paddingBottom-15 {
    padding-bottom: 15px;
}
.color-black {
    color: #000;
}
.cursor-none {
    cursor: none;
}
