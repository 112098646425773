.label {
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
  color: #1e2126;
  letter-spacing: 0.5px;
  line-height: 24px;
  background: #d9cb9a;
  border-radius: 5px;
  padding: 0 8px;
  text-transform: uppercase;
}

.sidebar-pages {
  background: #f6f6f6;
  //background: #a2d8f4;
  border-radius: 10px;
  padding: 20px;
  margin-left: 20px;
  margin-top: -15px;

  &--mb {
    margin-bottom: 60px;
  }

  &__ttl {
    font-weight: 700;
    font-size: 18px;
    color: #2c3647;
    margin-bottom: 25px;
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    background: #ffffff;
    border-radius: 15px;
    font-size: 14px;
    color: #2c3647;
    line-height: 1.7;
    padding: 15px 38px 15px 15px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background: url("../images/ico-link-arrow-right.svg") center / contain
        no-repeat;
    }
  }

  &__infocus {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #2c3647;
    line-height: 1.7;

    img {
      width: 83px;
      height: auto;
      margin-right: 15px;
      border-radius: 5px;
    }
  }

  &__list {
    list-style-type: none;
  }
}

.references {
  margin-top: 26px;
  background: #f6f6f6;
  border-radius: 10px;
  padding: 15px 25px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__action {
    button {
      border: none;
      background: transparent;
      font-weight: 600;
      font-size: 14px;
      color: #2c3647;
      line-height: 20px;
      position: relative;
      padding-right: 22px;

      &::after {
        position: absolute;
        content: "";
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 7px;
        width: 12px;
        background: url("../images/ico-arrow-toogle.svg") center / contain
          no-repeat;
      }
    }

    &.active {
      button {
        font-size: 0px;

        &::after {
          transform: translateY(-100%) rotate(180deg);
        }
      }
    }
  }

  &__ttl {
    font-weight: 600;
    font-size: 18px;
    color: #2c3647;
    line-height: 30px;
  }

  &__body {
    padding: 23px 0 28px;
  }

  &__list {
    list-style-type: disc;
    padding-left: 15px;
  }

  &__item {
    font-size: 16px;
    color: #2c3647;
    line-height: 1.8;
    margin-bottom: 20px;
    // padding-left: 26px;
    position: relative;
    word-wrap: break-word;
    
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 5px;
      width: 18px;
      height: 18px;
      // background: url("../images/ico-file.svg") center / contain no-repeat;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.search-bar {
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 10px;
  // box-shadow: 0 35px 26px -14px rgba(8, 26, 24, 0.12);
  box-shadow: 0 10px 20px -4px rgba(47, 52, 78, 0.08);

  @media (max-width: 767px) {
    flex-wrap: wrap;
    box-shadow: none;
  }

  .ui.input {
    width: calc(100% - 130px);
    display: block;

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  i.icon {
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 15px;
    color: #c4cad6;
    margin: 0;
    font-size: 16px;
  }

  .ui.input > input {
    height: 50px;
    border: none;
    border-radius: 10px 0 0 10px;
    width: 100%;
    display: block;
    padding: 0 20px 0 56px;
    font-size: 16px;

    @media (max-width: 767px) {
      border: 1px solid #dbe7ea;
      border-radius: 10px;
    }
  }
  .css-1d8n9bt {
    padding-top: 10px;
  }
  .css-b62m3t-container {
    height: 50px;
    border: none;
    border-radius: 10px 10px 10px 10px;
    width: 100%;
    display: block;
    padding: 0 20px 0 56px;
    font-size: 16px;
    background: white;

    @media (max-width: 767px) {
      border: 1px solid #dbe7ea;
      border-radius: 10px;
    }
  }
  .css-1s2u09g-control {
    border: none;
    // padding-top: 6px;
    // padding-bottom: 6px;
  }

  .css-319lph-ValueContainer {
    border: none;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .css-6j8wv5-Input {
    border: none;
    padding-top: 10px;
  }
  .css-6j8wv5-Input:focus {
    outline: none !important;
    border: 1px solid red;
    box-shadow: 0 0 10px #719ece;
  }
  .css-1pahdxg-control:focus {
    outline: none !important;
    border: none;
    border-color: transparent;
    //border-color: red;
    //box-shadow: 0 0 10px #719ECE;
  }
  .css-1pndypt-Input {
    padding-top: 8px;
  }
  .ui.button {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    height: 50px;
    text-transform: uppercase;
    width: 139px;

    @media (max-width: 767px) {
      width: 30%;
    }
  }
}

.only-mobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.only-desktop {
  @media (max-width: 767px) {
    display: none;
  }
}

.form-group {
  margin-bottom: 20px;

  &__label {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    color: #2c3647;
    margin-bottom: 8px;
  }

  .ui.input {
    display: block;
    width: 100%;
  }

  .ui.input > input {
    display: block;
    width: 100%;
  }
}

.status {
  display: inline-flex;
  font-weight: 700;
  font-size: 10px;
  color: #90949d;
  letter-spacing: 1px;
  height: 20px;
  padding: 0 8px;
  border-radius: 5px;
  background: #e8e8e8;
  align-items: center;
  text-transform: uppercase;
  margin-left: 10px;

  &--green {
    color: #1fbb20;
    background: #edfaee;
  }

  &--red {
    color: #fd6f6f;
    background: #ffe4e4;
  }

  &--blue {
    color: #5587f2;
    background: #e6edfd;
  }

  &--line-trought {
    text-decoration: line-through;
  }

  @media (max-width: 767px) {
    font-size: 0;
    letter-spacing: 0;

    &::before {
      content: attr(data-lang);
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.react-datepicker-component .react-datepicker-input {
  width: 100%;
  min-width: auto;
  height: 44px;
  height: 44px;
  border: 1px solid #dbe7ea;
  border-radius: 10px;
  font-size: 14px;
  background: #fff;
  margin-right:20px;
}

.react-datepicker-component .react-datepicker-input:hover {
  background: transparent;
  border-color: #dbe7ea;
}

.react-datepicker-component .react-datepicker-input input {
  color: #2c3647;
  padding-right: 35px;
  font-weight: 400;
  font-size: 14px;

  &::-webkit-input-placeholder {
    color: rgba(191, 191, 191, 0.87);
    font-weight: 400;
    font-size: 14px;
  }

  &:-moz-placeholder {
    color: rgba(191, 191, 191, 0.87);
    font-weight: 400;
    font-size: 14px;
  }

  &:-ms-input-placeholder {
    color: rgba(191, 191, 191, 0.87);
    font-weight: 400;
    font-size: 14px;
  }

  &:-moz-placeholder {
    color: rgba(191, 191, 191, 0.87);
    font-weight: 400;
    font-size: 14px;
  }
}

.react-datepicker-component .react-datepicker-input:hover input {
  color: #2c3647;
}

.react-datepicker-component .react-datepicker-input.is-open {
  background: transparent;
  border-color: #dbe7ea;
}

.react-datepicker-component {
  width: 100%;
}
