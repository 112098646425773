@media print {
  .detail-page__header {
    padding: 0px;
  }

  .detail-page__col1 {
    display: none;
  }

  article,
  aside,
  footer,
  header,
  nav {
    display: none;
  }

  .st-sticky-share-buttons {
    display: none;
  }

  .header__hamburger {
    display: none;
  }

  .header__hamburger {
    display: none;
  }

  .header__hamburger {
    display: none;
  }

  .breadcrumbs {
    display: none;
  }

  .detail-page__search-bar {
    display: none;
  }

  .detail-page__tr-text {
    margin-top: 30px;
    font-size: 16px;
    color: #2c3647;
    line-height: 1.5;
  }

  .page-navigation {
    display: none;
  }

  .header__hamburger {
    display: none;
  }

  a {
    display: none;
  }

  h1 {
    page-break-before: always;
  }

  .references {
    page-break-before: always;
  }

  .content-block {
    page-break-after: always;
  }

  .non-printable {
    display: none !important;
  }

  @page {
    margin: 2cm;
  }
}