a:hover {
    color: $color-blue;
}

.ui.button {
    height: 50px;
    text-transform: uppercase;
    border-radius: 10px;
    color: #2c3647;
}
.ui.button:focus {
  box-shadow: 6px 6px 10px rgba(47, 52, 78, 0.2);
}

.ui.basic.button,
.ui.basic.buttons .button {
    border-radius: 10px;
    text-transform: uppercase;
}

.ui.lightblue.button,
.ui.lightblue.buttons .button {
    background: #a2d8f4;
    color: #2c3647;
}

.ui.lightblue.button:hover,
.ui.lightblue.buttons .button:hover,
.ui.lightblue.button:active,
.ui.lightblue.buttons .button:active,
.ui.lightblue.button:focus,
.ui.lightblue.buttons .button:focus {
    background: #96cff2;
}

.ui.green.button,
.ui.green.buttons .button {
    background: #789d73;
    color: white;
}

.ui.green.button:hover,
.ui.green.buttons .button:hover,
.ui.green.button:active,
.ui.green.buttons .button:active,
.ui.green.button:focus,
.ui.green.buttons .button:focus {
    background: #91cd32;
}

.ui.blue.button,
.ui.blue.buttons .button {
    background: #195a7c;
}

.ui.blue.button:hover,
.ui.blue.buttons .button:hover,
.ui.blue.button:active,
.ui.blue.buttons .button:active,
.ui.blue.button:focus,
.ui.blue.buttons .button:focus {
    background: #2e6a88;
}

.ui.teal.button,
.ui.teal.buttons .button {
    background: #1676a7;
}

.ui.pagination.menu {
    font-size: 14px;
    border: none;
    box-shadow: none;

    &::after {
        display: none;
    }
}

.ui.pagination.menu .item {
    margin-right: 5px;
    padding: 0;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;

    background: #ffffff;
    border: 1px solid #dcdfe3;
    box-shadow: 0 2px 6px 0 rgba(47, 52, 78, 0.08);
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
    color: #2c3647;

    &:last-child {
        margin-right: 0;
    }
}

.ui.menu .item:before {
    display: none;
}

.ui.pagination.menu .active.item {
    background: #a2d8f4 !important;
    border-color: #a2d8f4 !important;
    padding: 0 !important;
    color: #fff;
}

.ui.pagination.menu .item:last-child {
    border-radius: 2px;
}

.ui.breadcrumb .section {
    font-weight: 600;
    font-size: 12px;
    color: #2c3647;
    line-height: 2;
    text-transform: uppercase;
    text-decoration: underline;
}

.ui.breadcrumb .active.section {
    font-weight: 600;
    text-decoration: none;
}

.ui.input > input {
    border-radius: 10px;
    padding: 0 15px;
    height: 44px;
    font-size: 16px;
}

.ui.search.dropdown.active > input.search,
.ui.search.dropdown.visible > input.search {
    font-size: 16px;
}

.ui.input.focus > input,
.ui.input > input:focus {
    border-color: #66aed3;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
    width: 14px;
    height: 14px;
    border-color: #888ea1;
    box-sizing: border-box;
    top: 2px;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
    font-size: 9px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    top: 1px;
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
    border-color: #5dc2d0;
    background: #5dc2d0;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
    color: #fff;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:before {
    border-color: #5dc2d0;
    background: #5dc2d0;
}

.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type="radio"]):indeterminate:focus ~ label:after {
    color: #fff;
}

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #70d9e7;
}

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
    background: #70d9e7;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
    background: #70d9e7;
    width: 14px;
    height: 14px;
}

.ui.radio.checkbox input:focus:checked ~ .box:before,
.ui.radio.checkbox input:focus:checked ~ label:before {
    background: #fff;
}

.ui.radio.checkbox input:focus:checked ~ .box:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
    background: #70d9e7;
}

.ui.radio.checkbox input:focus ~ .box:before,
.ui.radio.checkbox input:focus ~ label:before {
    background-color: #fff;
}

.ui.radio.checkbox input:focus ~ .box:after,
.ui.radio.checkbox input:focus ~ label:after {
    background: #70d9e7;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
    border-color: #70d9e7;
}

.ui.selection.dropdown > .dropdown.icon,
.ui.dropdown > .dropdown.icon {
    width: 10px;
    height: 6px;
    background: url("../images/ico-dropdown.svg") center / contain no-repeat;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    padding: 0;
    margin: 0;
    &::before {
        display: none;
    }
}

.ui.active.selection.dropdown > .dropdown.icon,
.ui.visible.selection.dropdown > .dropdown.icon {
    transform: translateY(-50%) rotate(180deg);
}

// .ui.selection.dropdown>.dropdown.icon.clear {
//   background: url(./images/ico-close-popup.svg) center no-repeat;
//   background-size: contain;
// }

// .ui.selection.dropdown>.dropdown.icon.search {
//   background: url(./images/ico-search.svg) center no-repeat;
//   background-size: contain;
// }
.ui.input > input {
    border-color: #dbe7ea;
}
