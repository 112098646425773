* {
  margin: 0;
  padding: 0;
  outline: none !important;
  text-decoration: none;
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

//ul,ol {}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html,
* html body {
  height: 100%;
}

body {
  min-height: 100%;
  min-width: 320px;
  color: $text-color;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.15;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  min-width: 320px;
  // overflow: hidden;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}


.container {
  max-width: 1300px;
  padding: 0 30px;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

.ui.selection.dropdown>i.dropdown.icon {
  margin: 0px !important;
}

::-webkit-input-placeholder {
  color: #90949D;
}

::-moz-placeholder {
  color: #90949D;
}

:-ms-input-placeholder {
  color: #90949D;
}

:-moz-placeholder {
  color: #90949D;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
}

:focus:-ms-input-placeholder {
  color: transparent;
}

:focus:-moz-placeholder {
  color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.important-banner {
  width: 100%;
  text-align: center;
  color: white;
  background-color: red;
  padding: 15px;
}

.error-color {
  color: #db2828
}

.width100 {
  width: 100% !important;

  i {
    float: right;
  }
}

.relatedInFocus {
  width: 70px !important;

  img {
    width: 60px !important;
  }
}

.btn-w8 {
  width: 8px !important;
}

.min-width300 {
  min-width: 300px !important;
}

.paddingTop10 {
  padding-top: 10px !important;
}

.paddingTop15 {
  padding-top: 15px !important;
}

.paddingTop18 {
  padding-top: 18px !important;
}

.cursorPointer {
  cursor: pointer;

  &_color {
    color: #1676a7
  }
}