.app-page {
    display: flex;
    min-height: 100vh;

    &__sidebar {
        width: 18%;

        @media (max-width: 992px) {
            display: none;
        }
    }

    &__content {
        width: 82%;
        background: #f6f6f6;

        @media (max-width: 992px) {
            width: 100%;
        }
    }

    &__header {
        height: 76px;
        border-bottom: 1px solid #e7e9ee;
        display: flex;
        justify-content: space-between;
        padding: 0 24px;
        align-items: center;

        @media (max-width: 767px) {
            background: #fff;
            height: 72px;
            padding: 0 15px;
        }

        .ui.inline.dropdown .menu {
            width: 200px;
        }

        .ui.dropdown .menu>.item {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
            line-height: 24px;
        }

        .ui.inline.dropdown .dropdown.icon {
            position: absolute;
            right: 0;
        }
    }

    &__hamburger {
        opacity: 0;
        z-index: -1;

        @media (max-width: 767px) {
            opacity: 1;
            z-index: 1;
        }
    }

    &__user {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        line-height: 24px;
        padding: 10px 0;
        margin-right: 22px;

        img {
            border-radius: 50%;
            margin-left: 12px;
        }

        @media (max-width: 767px) {
            span {
                opacity: 0;
                z-index: -1;
            }

            img {
                margin: 0;
            }
        }
    }

    // &__body {
    //   // min-height: calc(100vh - 76px);
    //   // display: flex;
    // }
}

.app-sidebar {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__logo {
        margin-bottom: 60px;
        padding: 22px 30px 0;

        img {
            max-width: 100%;
        }
    }

    &__list {
        padding-left: 30px;
        list-style-type: none;
    }

    &__item {
        margin-bottom: 30px;
    }

    &__link {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        line-height: 1.7;
        display: inline-flex;
        align-items: center;

        svg {
            fill: #888ea1;
            margin-right: 12px;
        }

        &.active {
            //color: #5BC1BD;
            color: #79c3ea;

            svg {
                //fill: #5BC1BD;
                fill: #79c3ea;
            }
        }

        &:hover {
            //color: #5BC1BD;
            color: #79c3ea;

            svg {
                //fill: #5BC1BD;
                fill: #79c3ea;
            }
        }
    }

    &__bottom {
        padding: 40px 30px;
        border-top: 1px solid #e7e9ee;
        margin-top: auto;
    }

    &__logout {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        line-height: 1.7;
        display: inline-flex;
        align-items: center;

        svg {
            fill: #888ea1;
            margin-right: 12px;
        }

        &:hover {
            //color: #5BC1BD;
            color: #79c3ea;

            svg {
                //fill: #5BC1BD;
                fill: #79c3ea;
            }
        }
    }
}

.app-content {
    padding: 33px 24px;

    @media (max-width: 767px) {
        padding: 15px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            margin-bottom: 25px;

            &--mobile-wrap {
                flex-direction: column;
            }
        }

        .ui.button {
            height: 44px;
            font-size: 13px;
            letter-spacing: 1px;
            white-space: nowrap;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 28px;
        color: #2c3647;

        @media (max-width: 767px) {
            font-size: 20px;
        }
    }

    //&__body {}
}

.knowledge {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;

    @media (max-width: 767px) {
        border-radius: 0;
        margin-left: -15px;
        margin-right: -15px;
    }

    &__filter {
        padding: 30px 20px 0 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            border-bottom: 15px solid #f6f6f6;
        }

        >span {
            display: inline-block;
            margin-right: 15px;
            white-space: nowrap;
            height: 44px;

            @media (max-width: 767px) {
                display: none;
            }
        }

        .ui.multiple.dropdown>.label {
            white-space: nowrap;
        }

        .ui.selection.dropdown {
            width: 140px;
            min-width: auto;
            margin-right: 10px;
            border-radius: 10px;
            height: 44px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 20px;
            // overflow: hidden;
            // justify-content: flex-end;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }

            @media (max-width: 450px) {
                width: calc(50% - 5px);

                &:nth-child(4) {
                    margin-right: 0;
                }
            }
        }

        .react-datepicker-component {
            margin-right: 35px;
            width: 130px;
            margin-bottom: 20px;

            @media (max-width: 450px) {
                width: calc(50% - 5px);

                &:nth-child(6) {
                    margin-right: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .react-datepicker.floating {
            right: 0;
        }
    }

    &__search {
        width: 429px;
        margin-right: 20px;
        margin-bottom: 20px;

        @media (max-width: 450px) {
            width: 100%;
            margin-right: 0;
        }
    }
}

.table-knowledge {
    &__mobile-text {
        display: none;

        @media (max-width: 767px) {
            display: inline-block;
            width: 139px;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
        }
    }

    &__more {
        display: inline-flex;
        margin-left: 10px;
        font-weight: 600;
        font-size: 12px;
        color: #90949d;
        letter-spacing: 0;
        line-height: 20px;
    }

    &__head {
        border-top: 1px solid #dbe7ea;
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__lang {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &__mobile-lang {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
        background: #f8f8f8;
        padding: 15px 0 0 0;
        padding-left: 154px;
        margin-top: 15px;

        .status {
            margin-bottom: 15px;
        }
    }

    &__tr {
        display: flex;
        min-height: 54px;
        align-items: center;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            padding: 0 15px;
            border-bottom: 15px solid #f6f6f6;
        }

        &--additional {
            background: #f8f8f8;
        }
    }

    &__td_d {
        &:nth-child(2) {
            width: 25%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                min-height: 42px;
            }

            span {
                position: relative;
                padding-left: 20px;
                //cursor: pointer;
                display: inline-block;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    //background: url(../images/ico-dropdown.svg) center / contain no-repeat;
                    width: 10px;
                    height: 6px;
                    transform: translateY(-50%);
                }

                &.active {
                    &::before {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }
    }

    &__td {
        font-size: 14px;
        color: #2c3647;

        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            padding-left: 2%;
            width: 6%;
            padding-right: 2%;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(2) {
            width: 25%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                min-height: 42px;
            }

            span {
                position: relative;
                padding-left: 20px;
                cursor: pointer;
                display: inline-block;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    background: url(../images/ico-dropdown.svg) center / contain no-repeat;
                    width: 10px;
                    height: 6px;
                    transform: translateY(-50%);
                }

                &.active {
                    &::before {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }

        &:nth-child(3) {
            width: 27%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                min-height: 42px;
                flex-wrap: wrap;
                padding-top: 5px;

                .only-mobile {
                    width: 100%;
                }
            }
        }

        &:nth-child(4) {
            width: 14%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                min-height: 42px;
            }
        }

        &:nth-child(5) {
            width: 14%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                min-height: 42px;
            }
        }

        &:nth-child(6) {
            width: 14%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                min-height: 42px;
            }
        }
    }
}

.table-api-key {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;

    @media (max-width: 767px) {
        margin: 0 -15px;
        border-radius: 0;
    }

    &__save {
        background: transparent;
        border: none;
        font-size: 14px;
        color: $color-blue;
    }

    &__cancel {
        background: transparent;
        border: none;
        font-size: 14px;
        color: #90949d;
        margin-right: 12px;
    }

    &__head {
        height: 64px;
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #e7e9ee;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__tr {
        display: flex;
        align-items: center;
        padding: 9px 30px 9px;
        width: 100%;
        min-height: 54px;

        &.active {
            background: #f8f8f8;
        }

        @media (max-width: 767px) {
            flex-wrap: wrap;
            padding: 15px 15px 9px;
            border-bottom: 15px solid #f6f6f6;

            &.active {
                background: #fff;
            }
        }
    }

    &__mobile {
        display: none;

        @media (max-width: 767px) {
            display: inline-block;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
            margin-right: 30px;
        }
    }

    &__td {
        font-size: 14px;
        color: #2c3647;

        &:nth-child(1) {
            width: 25%;
            position: relative;

            .only-mobile {
                position: absolute;
                right: 0;
                top: 0;
            }

            @media (max-width: 767px) {
                width: 100%;
                border-bottom: 1px solid #dbe7ea;
                padding-bottom: 17px;
            }
        }

        &:nth-child(2) {
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
                padding: 15px 0;
            }
        }

        &:nth-child(3) {
            width: 25%;
            text-align: right;

            @media (max-width: 767px) {
                width: 100%;

                .only-mobile {
                    div {
                        display: flex;
                        width: 100%;
                        border-top: 1px solid #dbe7ea;
                        padding: 15px 0 5px;

                        .ui.button {
                            width: 50%;
                            height: 40px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .ui.button.icon {
            background: transparent;
            padding: 0;
            height: auto;

            &:nth-child(1) {
                margin-right: 20px;
            }
        }

        i.icon,
        i.icons {
            font-size: 16px;
        }

        &--th {
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
        }
    }
}

.create-api-key {
    display: block;
    margin-bottom: 82px;
    width: 100%;
    max-width: 457px;

    &__info {
        position: relative;
        margin-top: 19px;
        font-size: 14px;
        color: #888ea1;
        line-height: 20px;
        padding-left: 26px;

        &::before {
            position: absolute;
            content: "";
            background: url("../images/ico-info.svg") center / contain no-repeat;
            width: 16px;
            height: 18px;
            left: 0;
            top: 2px;
        }
    }

    table {
        text-align: left;
        margin-bottom: 25px;

        tr {
            td {
                font-weight: 600;
                font-size: 14px;
                color: #2c3647;
                padding: 7px 0;
            }

            th {
                font-size: 16px;
                color: #2c3647;
                font-weight: 400;
                padding: 7px 0;

                &:nth-child(1) {
                    width: 150px;
                }
            }
        }
    }

    &__label {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        color: #2c3647;
    }

    .ui.button {
        display: block;
        width: 139px;
        margin-top: 20px;
        height: 44px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .ui.input,
    .ui.input>input {
        display: block;
        width: 100%;
    }
}

.table-medical-conditions {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;

    @media (max-width: 767px) {
        border-radius: 0;
        margin: 0 -15px;
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__save {
        background: transparent;
        border: none;
        font-size: 14px;
        color: $color-blue;
    }

    &__cancel {
        background: transparent;
        border: none;
        font-size: 14px;
        color: #90949d;
        margin-right: 12px;
    }

    &__tr {
        display: flex;
        width: 100%;
        padding: 0 30px;
        min-height: 54px;
        align-items: center;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            padding: 0 15px;
            border-bottom: 15px solid #f6f6f6;
        }
    }

    &__mobile-desc {
        display: none;
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        margin-right: 30px;

        @media (max-width: 767px) {
            display: inline-block;
        }
    }

    &__td {
        font-size: 14px;
        color: #2c3647;

        .ui.button.icon {
            background: transparent;
            padding: 0;
            height: auto;

            &:nth-child(1) {
                margin-right: 5px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-right: 20px;

                @media (max-width: 767px) {
                    margin-right: 15px;
                }
            }
        }

        i.icon,
        i.icons {
            font-size: 16px;
            width: auto;
        }

        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 5%;

            @media (max-width: 767px) {
                border-bottom: 1px solid #dbe7ea;
                height: 54px;
                padding-top: 15px;
            }
        }

        &:nth-child(2) {
            width: 25%;
            padding-right: 15px;
            position: relative;

            &.pin-ico {
                &::before {
                    content: "";
                    position: absolute;
                    left: -26px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    background: url("../images/ico-pin.svg");
                    cursor: pointer;

                    @media (max-width: 767px) {
                        position: static;
                        display: inline-block;
                        transform: none;
                        margin-right: 10px;
                    }
                }
            }

            &.unpin-ico {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: -26px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 16px;
                    height: 16px;
                    background: url("../images/ico-unpin.svg") center / contain no-repeat;
                    cursor: pointer;

                    @media (max-width: 767px) {
                        position: static;
                        display: inline-block;
                        transform: none;
                        margin-right: 10px;
                    }
                }
            }

            @media (max-width: 767px) {
                width: 94%;
                position: relative;
                border-bottom: 1px solid #dbe7ea;
                height: 54px;
                padding-top: 15px;
                padding-right: 0;

                span {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: calc(100% - 100px);
                }

                .only-mobile {
                    div {
                        position: absolute;
                        right: 0;
                        top: 15px;
                    }
                }
            }

            .ui.selection.dropdown {
                width: 100%;
            }
        }

        &:nth-child(3) {
            width: 24%;

            @media (max-width: 767px) {
                width: 100%;
                padding: 15px 0;
            }

            .ui.selection.dropdown {
                width: 250px;

                @media (max-width: 767px) {
                    width: 100%;
                }

                .menu>.item:not(.addition) {
                    .text {
                        display: inline-block;
                        font-weight: 600;
                        font-size: 12px;
                        color: #976318;
                        letter-spacing: 0.5px;
                        line-height: 24px;
                        background: rgba(255, 167, 37, 0.15);
                        border-radius: 5px;
                        padding: 0 8px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &:nth-child(4) {
            width: 20%;

            @media (max-width: 767px) {
                width: 100%;
                padding: 15px 0;
            }

            .ui.selection.dropdown {
                width: 220px;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        &:nth-child(5) {
            width: 18%;
            text-align: left;

            @media (max-width: 767px) {
                width: 100%;
                text-align: left;

                .only-mobile {
                    div {
                        display: flex;
                        width: 100%;
                        border-top: 1px solid #dbe7ea;
                        padding: 15px 0;

                        .ui.button {
                            width: 50%;
                            height: 40px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        &:nth-child(6) {
            width: 8%;
        }
    }
}

.add-user {
    &__desc {
        background: #ffffff;
        box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;

        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__pas-input {
        margin-bottom: 20px;
    }

    &__btn {
        // margin-top: auto;
        padding-top: 20px;

        .ui.button {
            width: 139px;
            border-radius: 10px;
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 1px;

            @media (max-width: 767px) {
                height: 44px;
            }
        }

        .ui.button.btn-save {
            margin-right: 20px;
        }

        .ui.button.btn-cancel {
            background: #ffffff;
            border: 1px solid #c4cad6;
            color: #90949d;
        }
    }

    &__box {
        max-width: 457px;
        width: 100%;
        min-height: calc(100vh - 205px);
        display: flex;
        flex-direction: column;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
        width: calc(100% + 12px);
    }

    &__col {
        width: 100%;
        padding: 0 6px;
    }

    &__col-2 {
        width: 50%;
        padding: 0 6px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__password {
        .ui.button {
            width: 139px;
            background: #ffffff;
            border: 1px solid #c4cad6;
            border-radius: 10px;
            font-weight: 600;
            font-size: 13px;
            color: #90949d;
            letter-spacing: 1px;
            margin-right: 20px;

            @media (max-width: 767px) {
                height: 44px;
            }
        }
    }
}

.file-upload {
    position: relative;
    display: flex;
    padding: 12px 15px;
    background: #ffffff;
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    align-items: center;

    &__text {
        align-self: center;
        font-size: 14px;
        color: #90949d;
        width: 100%;
        text-align: center;
        padding: 0 10px;
    }

    &__btn {
        background: url("../images/ico-plus.svg") center / contain no-repeat;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
    }

    &__img {
        flex-shrink: 0;
        display: block;
        font-size: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px dashed #dbe7ea;
        background: url("../images/ico-photo.svg") center no-repeat;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    &__input {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: block;
        opacity: 0;
        z-index: 1;
        width: 100%;
        cursor: pointer;
    }
}

.table-in-focus {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;

    @media (max-width: 767px) {
        margin: 0 -15px;
        border-radius: 0;
    }

    &__action {
        @media (max-width: 767px) {
            position: absolute;
            right: 15px;
            top: 18px;
        }
    }

    &__mobile-ttl {
        display: none;
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        line-height: 1.7;
        margin-right: 20px;
        width: 88px;

        @media (max-width: 767px) {
            display: inline-block;
        }
    }

    &__filter {
        padding: 30px 20px;
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            padding: 20px 15px;
            border-bottom: 15px solid #edeeef;
        }
    }

    &__search {
        max-width: 429px;
        width: 100%;
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__body {
        padding: 10px 0;

        @media (max-width: 767px) {
            padding: 5px 0;
        }
    }

    &__tr {
        display: flex;
        min-height: 54px;
        align-items: center;
        padding: 0 30px;
        margin-bottom: 5px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            background: #f8f8f8;
        }

        @media (max-width: 767px) {
            padding: 0 15px;
            flex-wrap: wrap;
            border-bottom: 15px solid #edeeef;
        }
    }

    &__td {
        font-size: 14px;
        color: #2c3647;
        line-height: 1.7;

        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 4%;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(2) {
            width: 3%;

            @media (max-width: 767px) {
                width: 100%;
                padding-bottom: 15px;
                padding-top: 10px;
                border-bottom: 1px solid #dbe7ea;
            }

            &.pin-ico {
                padding-right: 15px;
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 16px;
                    height: 16px;
                    background: url("../images/ico-pin.svg") center / contain no-repeat;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -39%;
                    cursor: pointer;
                }
            }

            &.unpin-ico {
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 16px;
                    height: 16px;
                    background: url("../images/ico-unpin.svg") center / contain no-repeat;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -39%;
                    cursor: pointer;
                }
            }
        }

        &:nth-child(3) {
            width: 7%;
        }

        &:nth-child(4) {
            width: 59%;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                display: flex;
                padding-right: 0;
                padding-top: 15px;

                .table-in-focus__mobile-ttl {
                    flex-shrink: 0;
                }
            }

            .ui.input {
                display: block;
                width: 100%;
            }

            .ui.input>input {
                max-width: 400px;
                width: 100%;
                display: block;
            }
        }

        &:nth-child(5) {
            width: 7%;

            @media (max-width: 767px) {
                width: 100%;
                padding: 15px 0;
            }
        }

        &:nth-child(6) {
            width: 20%;
            text-align: right;
        }

        .ui.button.icon {
            background: transparent;
            padding: 0;
            height: auto;
            width: auto;

            &:nth-child(1) {
                margin-right: 5px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-right: 20px;
            }
        }

        i.icon,
        i.icons {
            font-size: 16px;
            width: auto;
        }
    }

    &__preview {
        width: 55px;
        height: 40px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 3px;
    }
}

.sort-icon {
    display: inline-block;
    margin-left: 7px;

    i.icon,
    i.icons {
        margin: 0;
        color: #c4cad6;
        width: auto;
    }
}

.search-filter {
    position: relative;
    display: block;
    width: 100%;

    .ui.input {
        display: block;
        width: calc(100% - 125px);
        position: relative;

        @media (max-width: 767px) {
            width: calc(100% - 80px);
        }

        &::before {
            position: absolute;
            content: "";
            background: url("../images/ico-search.svg") center / contain no-repeat;
            top: 14px;
            left: 20px;
            width: 16px;
            height: 16px;

            @media (max-width: 767px) {
                left: 10px;
            }
        }
    }

    .ui.input>input {
        display: block;
        width: 100%;
        height: 44px;
        padding: 0 5px 0 55px;

        @media (max-width: 767px) {
            padding-left: 30px;
        }
    }

    .ui.button {
        position: absolute;
        right: 0;
        top: 0;
        width: 139px;
        height: 44px;
        font-size: 13px;

        @media (max-width: 767px) {
            width: 90px;
        }
    }
}

.table-pagination {
    text-align: right;
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 767px) {
        justify-content: center;
    }

    .ui.pagination.menu {
        background: transparent;
        margin-left: 15px;

        @media (max-width: 767px) {
            margin: 0;
        }
    }

    .ui.selection.dropdown {
        width: 80px;
        min-width: auto;
        margin: 0 30px 0 15px;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__text {
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.table-drug-list {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;

    @media (max-width: 767px) {
        margin: 0 -15px;
        border-radius: 0;
    }

    &__filter {
        padding: 30px 20px;
        border-bottom: 1px solid #dbe7ea;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1200px) {
            flex-wrap: wrap;
        }

        @media (max-width: 767px) {
            padding: 20px 15px;
            border-bottom: 15px solid #edeeef;
        }
    }

    &__selects {
        display: flex;
        margin-left: 60px;
        align-items: center;

        @media (max-width: 1200px) {
            margin-left: 0;
            margin-top: 30px;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            width: 100%;
            margin-top: 20px;
        }

        >span {
            display: inline-block;
            margin-right: 15px;
            white-space: nowrap;

            @media (max-width: 767px) {
                display: none;
            }
        }

        .ui.selection.dropdown {
            width: 160px;
            min-width: auto;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            @media (max-width: 767px) {
                margin-right: 0;
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    &__search {
        max-width: 429px;
        width: 100%;
        // @media (max-width: 767px) {
        //   width: calc(100% - 55px);
        // }
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__body {
        padding: 10px 0;

        @media (max-width: 767px) {
            padding: 5px 0;
        }
    }

    &__footer {
        font-size: 14px;
        color: #90949d;
        text-align: right;
        padding: 0 30px 15px;

        @media (max-width: 767px) {
            padding-top: 12px;
        }
    }

    &__tr {
        display: flex;
        min-height: 54px;
        align-items: center;
        padding: 0 30px;
        position: relative;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            padding: 15px 15px 9px;
            border-bottom: 15px solid #f6f6f6;
        }
    }

    &__mobile-ttl {
        display: none;

        @media (max-width: 767px) {
            display: inline-block;
            width: 115px;
            font-weight: 600;
        }
    }

    &__load {
        margin: auto;
        display: table;
    }

    &__td {
        font-size: 14px;
        color: #2c3647;
        line-height: 1.7;
        word-wrap: break-word;

        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 4%;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(2) {
            width: 26%;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        &:nth-child(3) {
            width: 35%;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        &:nth-child(4) {
            width: 35%;

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
}

.table-translations {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    $c: &;

    @media (max-width: 767px) {
        margin: 0 -15px;
        border-radius: 0;
    }

    &__lang {
        text-transform: uppercase;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        font-weight: 600;
        color: #2c3647;

        @media (max-width: 767px) {
            padding-left: 0;
            font-weight: 400;
            // width: calc(100% - 64px)
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
            height: 6px;
            background: url(../images/ico-dropdown.svg) center / contain no-repeat;

            @media (max-width: 767px) {
                left: auto;
                right: 0;
            }
        }

        &.active {
            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &__nonelang {
        padding-left: 20px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
    }

    &__link {
        display: inline-flex;
        color: #2c3647;
        max-width: 100%;
        padding-right: 10px;

        &:hover {
            color: $color-blue;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media (max-width: 767px) {
            width: calc(100% - 70px);
        }
    }

    &__add {
        font-weight: 600;
        color: #2c3647;
        background: transparent;
        border: none;

        i.icon {
            font-size: 12px;
            color: #979797;
        }
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__body {
        padding: 10px 0;

        @media (max-width: 767px) {
            padding: 0;
            margin-top: -15px;
        }
    }

    &__edit-btn {
        @media (max-width: 767px) {
            position: absolute;
            right: 35px;
            top: 15px;
        }
    }

    &__tr {
        display: flex;
        min-height: 54px;
        align-items: center;
        padding: 0 30px;
        position: relative;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            padding: 15px 15px 9px;
            border-top: 15px solid #f6f6f6;
        }

        &--additional {
            background: #f8f8f8;

            @media (max-width: 767px) {
                padding: 10px 15px;
                position: relative;
                border: none;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 15px;
                    right: 15px;
                    content: "";
                    height: 1px;
                    background: #dbe7ea;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }

            #{$c}__td {
                &:nth-child(1) {
                    padding-left: 20px;

                    @media (max-width: 767px) {
                        padding: 10px 0;
                        border-bottom: none;
                    }
                }

                &:nth-child(2) {
                    padding-top: 0;
                    padding-bottom: 15px;
                }

                &:nth-child(3) {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    width: auto;
                }
            }
        }
    }

    &__td {
        font-size: 14px;
        color: #2c3647;
        line-height: 1.7;

        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 15%;

            .ui.input {
                width: 100px;
            }

            @media (max-width: 767px) {
                width: 100%;
                order: -1;
                border-bottom: 1px solid #dbe7ea;
                padding-bottom: 15px;
            }
        }

        &:nth-child(2) {
            width: 20%;

            .ui.input {
                width: 200px;
            }

            @media (max-width: 767px) {
                order: 2;
                width: 100%;
                padding-top: 15px;

                .only-mobile {
                    >div {
                        margin-top: 15px;
                        background: #fff;
                        padding: 10px 0;
                        width: 100%;
                        display: flex;
                        border-top: 1px solid #dbe7ea;

                        .ui.button {
                            width: 50%;
                            height: 40px;
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            width: 20%;

            .ui.input {
                width: 200px;
            }

            @media (max-width: 767px) {
                order: 2;
                width: 100%;
                padding-top: 15px;

                .only-mobile {
                    >div {
                        margin-top: 15px;
                        background: #fff;
                        padding: 10px 0;
                        width: 100%;
                        display: flex;
                        border-top: 1px solid #dbe7ea;

                        .ui.button {
                            width: 50%;
                            height: 40px;
                        }
                    }
                }
            }
        }

        &:nth-child(4) {
            text-align: left;
            width: 40%;
            margin-right: 15px;

            .ui.button.icon {
                background: transparent;
                padding: 0;
                height: auto;
                width: auto;

                &:nth-child(1) {
                    margin-right: 10px;
                }
            }
        }

        &:nth-child(5) {
            text-align: left;
            width: 10%;

            .ui.button.icon {
                background: transparent;
                padding: 0;
                height: auto;
                width: auto;

                &:nth-child(1) {
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    margin-right: 10px;
                }
            }
        }
    }

    &__mobile-ttl {
        display: none;

        @media (max-width: 767px) {
            display: inline-block;
            width: 70px;
            text-transform: capitalize;
            font-weight: 600;
        }
    }
}

.cbcontainer {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cbcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    left: 0px;
    z-index: 20;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #79c3ea;
}

.cbcontainer input:checked~.checkmark {
    background-color: #79c3ea;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.cbcontainer input:checked~.checkmark:after {
    display: block;
}

.cbcontainer .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.resolved-label {
    color: #79c3ea;
}

.ui.input.cs {
    margin-bottom: 20px;
    width: 500px;
}

.sfBody {
    padding: 5px;
    margin-bottom: 25px;
}


.sfInput {
    width: 500px;
}

.sfTitle {
    margin-right: 25px;
    padding-top: 15px;
    float: left;
    width: 20%;
}

.ui.basic.button.notVisible {
    display: none;
}

.ui.button.btn_bck_color.notVisible {
    display: none;
}

.add-trans__btn {
    padding-top: 20px;
}

.add-trans__btn-first {
    padding-top: 20px;
    margin-right: 25px;
    float: left;
}

.languages {
    float: right;
    position: relative;
    right: 210px;
}

.languagesUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.languagesLi {
    float: left;
    font-weight: bold;
    margin-right: 10px;
}

.languagesLi a {
    display: block;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.languagesButton {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #069;
    cursor: pointer;
}

.activeLanguage {
    color: #344c75;
}

.table-users {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    $c: &;

    @media (max-width: 767px) {
        margin: 0 -15px;
        border-radius: 0;
    }

    &__filter {
        padding: 30px 20px;
        border-bottom: 1px solid #dbe7ea;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            padding: 20px 15px;
            border-bottom: 15px solid #edeeef;
        }
    }

    &__search {
        max-width: 429px;
        width: 100%;
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__body {
        padding: 10px 0;

        @media (max-width: 767px) {
            padding: 5px 0;
        }
    }

    &__mobile-text {
        display: none;

        @media (max-width: 767px) {
            display: inline-block;
            width: 105px;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
        }
    }

    &__tr {
        display: flex;
        min-height: 54px;
        align-items: center;
        padding: 0 30px;
        width: 100%;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            padding: 0 15px;
            border-bottom: 15px solid #f6f6f6;
        }

        &--additional {
            background: #f8f8f8;
            min-height: 32px;
            border-bottom: 0;

            @media (max-width: 767px) {
                &:last-of-type {
                    padding-bottom: 20px;
                }

                &:first-of-type {
                    margin-top: -10px;
                }
            }

            #{$c}__td {
                &:nth-child(5) {
                    padding-left: 20px;
                }

                @media (max-width: 767px) {
                    display: none;

                    &:nth-child(5) {
                        display: block;
                    }
                }
            }
        }
    }

    &__td {
        font-size: 14px;
        color: #2c3647;

        .ui.input,
        .ui.input>input {
            display: block;
            width: 100%;
        }

        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 200px;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                border-bottom: 1px solid #dbe7ea;
                padding: 13px 0 17px;
                order: 1;
            }
        }

        &:nth-child(2) {
            width: 280px;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                padding: 7px 0;
                order: 2;
            }
        }

        &:nth-child(3) {
            width: 170px;
            padding-right: 15px;

            @media (max-width: 767px) {
                width: 100%;
                padding-right: 0;
                padding: 7px 0;
                order: 3;
            }
        }

        &:nth-child(4) {
            width: 90px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 7px 0;
                order: 4;
            }
        }

        &:nth-child(5) {
            width: 200px;

            .ui.checkbox label,
            .ui.checkbox+label {
                font-weight: 600;
                font-size: 14px;
                color: #2c3647;
                padding-left: 23px;
                text-transform: uppercase;
            }

            @media (max-width: 767px) {
                width: 100%;
                padding: 7px 0;
                order: 7;
            }
        }

        &:nth-child(6) {
            width: 145px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 7px 0;
                order: 6;
            }
        }

        &:nth-child(7) {
            width: 100px;

            @media (max-width: 767px) {
                width: 100%;
                padding: 7px 0;
                order: 4;
            }
        }

        &:nth-child(8) {
            width: 120px;
            text-align: right;

            @media (max-width: 767px) {
                width: auto;
            }

            .ui.button.icon {
                background: transparent;
                padding: 0;
                height: auto;
                width: auto;

                &:nth-child(1),
                &:nth-child(2) {
                    margin-right: 10px;
                }
            }

            i.icon,
            i.icons {
                font-size: 16px;
                width: auto;
            }
        }
    }

    &__more {
        display: inline-flex;
        margin-left: 10px;
        font-weight: 600;
        font-size: 12px;
        color: #90949d;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: lowercase;
    }

    &__nonelang {
        padding-left: 20px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;

        &--bold {
            font-weight: 700;
        }
    }

    &__lang {
        padding-left: 20px;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;

        &::before {
            position: absolute;
            content: "";
            width: 10px;
            height: 6px;
            background: url(../images/ico-dropdown.svg) center / contain no-repeat;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &.active {
            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

.website-tabs {
    // background: #fff;

    border-radius: 10px;

    .ui.menu {
        margin: 0;
    }

    .ui.secondary.pointing.menu {
        border-bottom: 1px solid #e7e9ee;
        background: #fff;
        border-radius: 10px 10px 0 0;

        @media (max-width: 767px) {
            background: transparent;
            margin-bottom: 15px;
            border-bottom: none;
        }
    }

    .ui.secondary.pointing.menu .item {
        height: 64px;

        @media (max-width: 767px) {
            background: #ffffff;
            border: 1px solid #dbe7ea;
            border-radius: 10px;
            font-weight: 600;
            font-size: 16px;
            color: #90949d;
            height: 44px;
            margin-right: 5px;
        }
    }

    .ui.secondary.pointing.menu .active.item {
        border-color: $color-blue;
        color: $color-blue;

        @media (max-width: 767px) {
            border-color: #dbe7ea;
            box-shadow: 0 12px 16px -2px rgba(47, 52, 78, 0.1);
            background: #fff;
        }
    }

    .ui.segment {
        margin: 0;
        border: none;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
    }

    &__content {
        border-radius: 0 0 10px 10px;
        padding: 20px;
        background: #fff;

        @media (max-width: 767px) {
            padding: 0;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    &__footer {
        display: flex;
        align-items: center;

        >div {
            display: flex;

            @media (max-width: 767px) {
                &:nth-child(1) {
                    width: calc(100% / 3);

                    .ui.button {
                        width: 100%;
                    }
                }

                &:nth-child(2) {
                    width: calc((100% / 3) * 2);

                    .ui.button {
                        width: 50%;
                    }
                }
            }
        }

        &--space-between {
            justify-content: space-between;
            margin-top: 30px;
        }

        .ui.button {
            height: 44px;
            width: 139px;
            font-size: 13px;
            letter-spacing: 1px;
            font-weight: 700;

            @media (max-width: 767px) {
                width: 30%;
            }
        }
    }
}

.table-pages {
    border: 1px solid #dbe7ea;
    border-radius: 10px;

    @media (max-width: 767px) {
        border-radius: 0;
        border: none;
    }

    &__header {
        padding: 15px 15px 15px 30px;
        border-bottom: 1px solid #dbe7ea;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ui.button {
            height: 44px;
            font-size: 13px;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
        }

        @media (max-width: 767px) {
            border-bottom: 15px solid #f6f6f6;
        }
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
    }

    &__tr {
        display: flex;
        width: 100%;
        padding: 0 30px;
        min-height: 54px;
        align-items: center;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            border-bottom: 15px solid #f6f6f6;
            padding: 0 15px;
            position: relative;
        }
    }

    &__td {
        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 5%;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(2) {
            width: 25%;

            @media (max-width: 992px) {
                width: 35%;
            }

            @media (max-width: 767px) {
                width: 100%;
                min-height: 54px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #dbe7ea;
            }
        }

        &:nth-child(3) {
            width: 25%;

            @media (max-width: 992px) {
                width: 35%;
            }

            @media (max-width: 767px) {
                width: 100%;
                min-height: 49px;
                display: flex;
                align-items: center;
            }
        }

        &:nth-child(4) {
            text-align: right;
            width: 45%;

            @media (max-width: 992px) {
                width: 25%;
            }

            @media (max-width: 767px) {
                width: auto;
                position: absolute;
                right: 15px;
                top: 18px;
            }
        }

        .ui.button.icon {
            background: transparent;
            padding: 0;
            height: auto;
            width: auto;

            &:nth-child(1) {
                margin-right: 20px;
            }
        }

        i.icon,
        i.icons {
            font-size: 16px;
            width: auto;
        }
    }

    &__link {
        color: #2c3647;

        &:hover {
            color: $color-blue;
        }
    }
}

.table-menu {
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        border: none;
        border-radius: 0;
        margin-bottom: 0;
        border-bottom: 40px solid #f6f6f6;
    }

    &:last-child {
        margin-bottom: 0;

        @media (max-width: 767px) {
            border-bottom: none;
        }
    }

    &__header {
        padding: 15px 15px 15px 30px;
        border-bottom: 1px solid #dbe7ea;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ui.button {
            height: 44px;
            font-size: 13px;
            letter-spacing: 1px;
        }

        @media (max-width: 767px) {
            border-bottom: 15px solid #f6f6f6;
        }
    }

    &__head {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
    }

    &__tr {
        display: flex;
        width: 100%;
        padding: 0 30px;
        min-height: 54px;
        align-items: center;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            position: relative;
            padding: 0 15px;
            border-bottom: 15px solid #f6f6f6;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__td {
        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 5%;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &:nth-child(2) {
            width: 30%;
            padding-right: 15px;

            @media (max-width: 767px) {
                padding-right: 0;
                display: flex;
                min-height: 54px;
                padding: 10px 0;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #dbe7ea;
            }
        }

        &:nth-child(3) {
            width: 30%;
            padding-right: 15px;

            @media (max-width: 767px) {
                padding-right: 0;
                display: flex;
                min-height: 54px;
                padding: 10px 0;
                align-items: center;
                width: 100%;
            }
        }

        &:nth-child(4) {
            text-align: right;
            width: 35%;

            @media (max-width: 767px) {
                width: 100%;

                .only-mobile {
                    >div {
                        background: #fff;
                        padding: 10px 0;
                        width: 100%;
                        display: flex;
                        border-top: 1px solid #dbe7ea;

                        .ui.button {
                            width: 50%;
                            height: 40px;
                        }
                    }
                }
            }
        }

        .ui.input,
        .ui.input>input {
            @media (max-width: 1140px) {
                width: 100%;
                display: block;
            }
        }

        .ui.selection.dropdown {
            @media (max-width: 1140px) {
                width: 100%;
            }
        }

        .ui.button.icon {
            background: transparent;
            padding: 0;
            height: auto;
            width: auto;

            &:nth-child(1) {
                margin-right: 5px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-right: 15px;
            }
        }

        i.icon,
        i.icons {
            font-size: 16px;
            width: auto;
        }
    }

    &__edit-btn {
        display: flex;

        @media (max-width: 767px) {
            position: absolute;
            right: 15px;
            top: 18px;
        }
    }

    &__link {
        color: #2c3647;

        &:hover {
            color: $color-blue;
        }
    }
}

.table-logo {
    border: 1px solid #dbe7ea;
    border-radius: 10px;

    &__header {
        font-weight: 600;
        font-size: 18px;
        color: #444b5b;
        padding: 25px 30px;
        border-bottom: 1px solid #dbe7ea;
    }

    &__body {
        padding: 20px;
    }

    &__tr {
        display: flex;
    }

    &__td {
        &:nth-child(1) {
            width: 340px;
        }

        &:nth-child(2) {
            width: calc(100% - 340px);
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.knowledge-preview {
    background: #fff;
    width: 100%;
    padding: 45px;

    @media (max-width: 992px) {
        padding: 20px;
    }

    .detail-page {
        margin: 0;
    }

    .detail-page__header {
        margin: 0 40% 0 0;

        @media (max-width: 992px) {
            margin: 0;
        }
    }

    .detail-page__col2 {
        margin-left: 0;
        width: 65%;

        @media (max-width: 992px) {
            width: 60%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .detail-page__col3 {
        width: 35%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.preview-actions {
    display: flex;
    position: relative;
    z-index: 2;

    @media (max-width: 767px) {
        margin-top: 20px;
    }

    &__elem {
        .ui.button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            @media (max-width: 767px) {}
        }

        &:last-child {
            .ui.button {
                margin-right: 0;
            }
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
    }

    .ui.button.dropdown {
        background: #ffffff;
        border: 1px solid #c4cad6;
        border-radius: 10px;
        height: 44px;
        display: inline-flex !important;
        align-items: center;
        background: transparent;
        border-color: #c4cad6;
        color: #90949d;
    }

    .ui.labeled.icon.button,
    .ui.labeled.icon.buttons .button {
        padding-left: 50px !important;
        padding-right: 20px !important;
    }

    .ui.labeled.icon.button>.icon,
    .ui.labeled.icon.buttons>.button>.icon {
        width: auto;
        left: 20px;
    }

    .ui.teal.button,
    .ui.teal.buttons .button {
        padding-left: 43px;
        padding-right: 43px;
    }
}

.preview-comments {
    background: #ffffff;
    border: 1px solid #dbe7ea;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    margin-top: 12px;
    text-transform: none;

    .ui.secondary.pointing.menu {
        margin-bottom: 0;
        border-color: #e7e9ee;
    }

    .ui.secondary.pointing.menu .item {
        height: 50px;
        color: #90949d;
        font-weight: 600;
    }

    .ui.secondary.pointing.menu .active.item {
        border-color: $color-blue;
        color: $color-blue;
    }

    .ui.segment {
        margin-top: 0;
        box-shadow: none;
        border: none;
    }

    &__list {
        padding: 20px;
    }

    //&__item {}
}

.table-attach {
    // width: 485px;
    background: #ffffff;
    border: 1px solid #dbe7ea;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    margin-top: 12px;
    text-transform: none;

    &__header {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e7e9ee;

        .table-attach__tr {
            margin: 0;
        }

        .table-attach__td {
            font-weight: 600;
        }
    }

    &__body {
        padding-bottom: 15px;
    }

    &__tr {
        display: flex;
        padding: 0 30px;
        margin-top: 15px;
        width: 100%;
    }

    &__td {
        font-size: 14px;
        color: #2c3647;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;

        &:nth-child(1) {
            width: 50%;

            a {
                color: #2c3647;

                i.icon,
                i.icons {
                    color: #2c3647;
                }

                &:hover {
                    color: $color-blue;

                    i.icon,
                    i.icons {
                        color: $color-blue;
                    }
                }
            }
        }

        &:nth-child(2) {
            width: 20%;
        }

        &:nth-child(3) {
            width: 30%;
        }
    }
}

.page-info {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
        border-radius: 0;
        width: calc(100% + 30px);
    }

    &__header {
        padding: 20px;
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        border-bottom: 1px solid #e7e9ee;
    }

    &__body {
        padding: 30px 20px 20px 20px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 5px;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    &__label {
        display: inline-block;
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
    }

    &__row {
        display: flex;
        margin: 10px -10px;
        width: calc(100% + 20px);

        @media (max-width: 992px) {
            flex-wrap: wrap;
        }
    }

    &__col {
        padding: 0 10px;

        .ui.selection.dropdown {
            display: flex;
            align-items: center;
            width: 100%;
            min-width: 0;
            height: 44px;
            border-radius: 10px;
        }

        .ui.input {
            display: block;
            width: 100%;

            &>input {
                display: block;
                height: 44px;
                width: 100%;
                border-radius: 10px;
            }
        }

        &:nth-child(1) {
            width: 408px;

            @media (max-width: 992px) {
                width: 50%;
            }

            @media (max-width: 767px) {
                width: 100%;
                margin-top: 15px;
            }
        }

        &:nth-child(2) {
            width: 266px;

            @media (max-width: 992px) {
                width: 50%;
            }

            @media (max-width: 767px) {
                width: 100%;
                margin-top: 15px;
            }
        }

        &:nth-child(3) {
            width: 266px;

            @media (max-width: 992px) {
                width: 50%;
                margin-top: 15px;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &:nth-child(4) {
            width: 172px;

            @media (max-width: 992px) {
                width: 50%;
                margin-top: 15px;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    &__versions {
        font-size: 12px;
        color: #90949d;
        min-width: 120px;

        &>span {
            display: inline-block;
            margin-right: 20px;
        }

        .ui.radio.checkbox {
            margin-right: 20px;
            margin-left: 22px;

            &:last-child {
                margin-right: 0;
            }

            label:before {
                left: -22px;
            }

            label:after {
                left: -22px;
            }

            &.concept {
                input.hidden+label {
                    background: #e8e8e8;
                    border-radius: 5px;
                    font-weight: 700;
                    font-size: 10px;
                    color: #90949d;
                    letter-spacing: 1px;
                    line-height: 20px;
                    padding: 0 8px;
                }
            }

            &.published {
                input.hidden+label {
                    background: #edfaee;
                    border-radius: 5px;
                    font-weight: 700;
                    font-size: 10px;
                    color: #1fbb20;
                    letter-spacing: 1px;
                    line-height: 20px;
                    padding: 0 8px;
                }
            }

            &.in-review {
                input.hidden+label {
                    background: rgba(85, 135, 242, 0.15);
                    border-radius: 5px;
                    font-weight: 700;
                    font-size: 10px;
                    color: #5587f2;
                    letter-spacing: 1px;
                    line-height: 20px;
                    padding: 0 8px;
                }
            }
        }
    }

    &__updated {
        font-size: 12px;
        color: #90949d;

        @media (max-width: 767px) {
            margin-top: 15px;
            width: 100%;
        }
    }
}

.edit-tabs {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    padding-bottom: 20px;

    .website-tabs__footer {
        padding: 0 20px 0;

        .ui.button {
            padding: 0;
        }
    }

    @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        border-radius: 0;
    }

    .ui.pointing.secondary.menu {
        margin: 0;
        border-bottom-color: #e7e9ee;
    }

    .ui.secondary.pointing.menu .active.item {
        border-color: $color-blue;
        color: $color-blue;
        font-weight: 600;
    }

    .ui.secondary.pointing.menu .item {
        color: #90949d;
        font-weight: 600;
        height: 60px;
    }

    .ui.segment {
        margin: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding: 20px;
    }
}

.position-relative {
    position: relative;
}

.modal-absolute-right {
    background: #ffffff;
    box-shadow: 0 10px 51px -4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 361px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    z-index: 10;

    @media (max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        margin-bottom: 30px;
    }

    &__body {
        height: 315px;

        @media (max-width: 767px) {
            height: calc(100% - 100px);
        }

        .ui.selection.dropdown,
        .ui.input {
            margin-bottom: 12px;

            &.indication {
                .menu>.item:not(.addition) {
                    .text {
                        display: inline-block;
                        font-weight: 600;
                        font-size: 12px;
                        color: #976318;
                        letter-spacing: 0.5px;
                        line-height: 24px;
                        background: rgba(255, 167, 37, 0.15);
                        border-radius: 5px;
                        padding: 0 8px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;

        .ui.button {
            width: 120px;

            @media (max-width: 767px) {
                width: 50%;
            }
        }
    }
}

.attach-big-table {
    background: #ffffff;
    border: 1px solid #dbe7ea;
    border-radius: 10px;

    @media (max-width: 767px) {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        border: none;
        border-radius: 0;
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 15px 30px;
        border-bottom: 1px solid #dbe7ea;
        align-items: center;

        .ui.button {
            height: 44px;
            width: 118px;
        }

        @media (max-width: 767px) {
            border-bottom: 15px solid #f6f6f6;
        }
    }

    &__header {
        border-bottom: 1px solid #dbe7ea;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__tr {
        display: flex;
        width: 100%;
        padding: 0 30px;
        align-items: center;
        min-height: 37px;

        @media (max-width: 767px) {
            border-bottom: 15px solid #f6f6f6;
            flex-wrap: wrap;
            position: relative;
            padding: 0 15px;
        }
    }

    &__mobile-text {
        display: none;

        @media (max-width: 767px) {
            display: block;
            width: 125px;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
        }
    }

    &__body {
        min-height: 336px;
        padding: 10px 0;
    }

    &__td {
        &--th {
            font-weight: 600;
        }

        &:nth-child(1) {
            width: 65%;

            @media (max-width: 767px) {
                width: 100%;
                height: 54px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #dbe7ea;
            }

            a {
                color: #2c3647;
                transition: all 0.2s ease-in-out;

                i.icon,
                i.icons {
                    color: $color-blue;
                }

                &:hover {
                    color: $color-blue;
                }
            }
        }

        &:nth-child(2) {
            width: 10%;

            @media (max-width: 767px) {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 9px 0 6px;
            }
        }

        &:nth-child(3) {
            width: 15%;

            @media (max-width: 767px) {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 7px 0 8px;
            }
        }

        &:nth-child(4) {
            width: 10%;
            text-align: right;

            @media (max-width: 767px) {
                width: auto;
                position: absolute;
                right: 15px;
                top: 15px;
            }

            .ui.button {
                padding: 0;
                height: auto;
                background: transparent;
                font-size: 16px;

                i.icon,
                i.icons {
                    color: $color-blue;
                }
            }
        }
    }
}

.edit-tab-history {
    display: flex;
    margin: 0 -10px;
    width: calc(100% + 20px);

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }

    &__left {
        padding: 0 10px;
        width: 45%;

        @media (max-width: 767px) {
            width: 100%;
            padding: 0;
        }
    }

    &__right {
        padding: 0 10px;
        width: 55%;

        .status {
            @media (max-width: 767px) {
                font-size: 10px;
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0;
            margin-top: 20px;
        }
    }
}

.edit-history {
    border: 1px solid #dbe7ea;
    border-radius: 10px;

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        letter-spacing: 0;
        padding: 20px 20px 30px;
        border-bottom: 1px solid #dbe7ea;
    }

    &__item {
        display: flex;
        padding: 20px;
        border-bottom: 1px solid #dbe7ea;
        align-items: center;
        cursor: pointer;

        &--active {
            background: #f8f8f8;
        }
    }

    &__body {
        height: 429px;
        overflow-y: auto;

        @media (max-width: 767px) {
            height: 100%;
        }
    }

    &__name {
        width: calc(100% - 70px);
        padding-right: 40px;
        font-size: 14px;
        color: #2a2f34;
        line-height: 20px;

        b {
            font-weight: 600;
        }
    }

    &__date {
        font-size: 12px;
        color: #9ba2a9;
        line-height: 18px;
    }

    &__keyboard {
        display: inline-block;
        width: 21px;
        height: 14px;
        background: url(../images/ico-keyboard.svg) center / contain no-repeat;
    }

    &__circle {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #888ea1;

        &--blue {
            background: #5587f2;
        }
    }

    &__status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 14px;
        margin-bottom: 5px;

        >* {
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__info {
        width: 70px;
    }
}

.history-changes {
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    min-height: 100%;

    &__header {
        display: flex;
        margin-bottom: 10px;
        padding: 20px 20px 20px;
        border-bottom: 1px solid #dbe7ea;
    }

    &__body {
        height: 429px;
        overflow-y: auto;

        @media (max-width: 767px) {
            height: 100%;
        }
    }

    &__ttl {
        width: calc(100% - 120px);
        padding-right: 75px;
        font-size: 14px;
        color: #2a2f34;
        line-height: 20px;

        b {
            font-weight: 600;
        }
    }

    &__date {
        font-size: 12px;
        color: #9ba2a9;
        line-height: 18px;
        width: 120px;
    }

    &__sub-ttl {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        letter-spacing: 0;
    }

    &__item {
        padding: 0 20px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        &--inline {
            display: flex;
            align-items: center;
        }
    }

    &__new {
        background: #f8f8f8;
        border: 1px solid #dbe7ea;
        border-radius: 10px;
        font-size: 14px;
        color: #2c3647;
        line-height: 20px;
        padding: 15px;
        margin: 12px 0;
    }

    &__old {
        background: #ffffff;
        border: 1px solid #dbe7ea;
        border-radius: 10px;
        padding: 15px;
        font-size: 14px;
        color: #2c3647;
        line-height: 20px;
        text-decoration: line-through;
        margin: 12px 0;
    }
}

.edit-tab-details {
    display: flex;
    margin: 0 -10px;
    width: calc(100% + 20px);

    @media (max-width: 992px) {
        flex-wrap: wrap;
    }

    &__left {
        width: 59%;
        padding-right: 20px;

        @media (max-width: 992px) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__right {
        width: 41%;

        @media (max-width: 992px) {
            width: 100%;
            margin-top: 20px;
        }
    }
}

.details-setting {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 20px;

    &__collaboration {
        display: flex;
        align-items: center;

        &>* {
            margin-right: 10px;
            color: $color-blue;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__editcollaboration {
        display: block;
        align-items: center;

        &>* {
            margin-right: 10px;
            color: $color-blue;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        margin-bottom: 12px;
    }

    &__item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.import-drug.ui.basic.button {
    height: 44px;
    width: 180px;
    background: #fff !important;
    border: 1px dashed #c4cad6;
    box-shadow: none;
}

.auto-modal.ui.modal {
    width: auto;
}

.auto-modal-680.ui.modal {
    width: 680px;
}

.import-modal {
    background: #ffffff;
    box-shadow: 0 8px 51px -4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
    padding: 30px;

    @media (max-width: 767px) {
        padding: 20px 10px;
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        margin-bottom: 28px;
    }

    &__desc {
        font-size: 16px;
        color: #2c3647;
        line-height: 22px;
        margin-bottom: 32px;
    }

    &__table {
        margin-bottom: 30px;
        border: 1px solid #dbe7ea;
        border-radius: 10px;
    }

    &__head {
        display: flex;
        height: 50px;
        padding: 0 15px;
        font-weight: 600;
        font-size: 14px;
        border-bottom: 1px solid #dbe7ea;
        align-items: center;
    }

    &__list {
        padding: 0 15px;
        padding-top: 20px;
    }

    &__item {
        margin-bottom: 20px;

        i.icon {
            color: $color-blue;
        }
    }

    &__footer {
        .ui.button {
            width: 120px;
            height: 44px;
        }

        .ui.button.importing {
            width: 150px;
            height: 44px;
        }

    }
}

.add-in-focus {
    height: 44px;
    display: flex !important;
    align-items: center;
}

.btn_bck_color {
    background-color: #a2d7f3 !important;
}

.background_none {
    background: none !important;
}

.imi_color {
    color: #79c3ea;

    &__close {
        color: #79c3ea;
        float: right;
        cursor: pointer;
    }
}

.margin10 {
    margin: 0 10px !important;
}

.import_color {
    color: #90949d;
}

.imi_error_color {
    color: #d71a1a;
}


.breadcrumb-link {
    color: #2c3647 !important;
    cursor: pointer;

    &:hover {
        color: #4183c4 !important;
    }
}

.center-loading {
    margin: auto;
    width: 0%;
}

.create-infocus {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    margin-bottom: 20px;

    @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -1px;
        width: calc(100% + 30px);
        border-radius: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        height: 64px;
        padding: 0 20px;
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        border-bottom: 1px solid #e7e9ee;
    }

    //&__editor {}

    &__body {
        padding: 20px;
    }

    &__row {
        display: flex;
        width: calc(100% + 20px);
        margin: 0 -10px;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    &__col {
        padding: 0 10px;

        .ui.input,
        .ui.input>input {
            display: block;
            width: 100%;
        }

        label {
            display: block;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
            margin-bottom: 8px;
        }

        &:nth-child(1) {
            width: calc(100% / 4);

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        &:nth-child(2) {
            width: calc(100% / 4);

            @media (max-width: 767px) {
                width: 100%;
                margin-top: 20px;
            }

            .ui.selection.dropdown {
                height: 44px;
                border-radius: 10px;
                border-color: #dbe7ea;
            }

            .ui.search.dropdown>input.search {
                height: 44px;
            }

            .ui.search.dropdown>.text {
                top: 3px;
            }
        }

        &:nth-child(3) {
            width: calc(100% / 4);

            @media (max-width: 767px) {
                width: 100%;
                margin-top: 20px;
            }
        }

        &:nth-child(4) {
            width: calc(100% / 4);

            @media (max-width: 767px) {
                width: 100%;
                margin-top: 20px;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        .ui.button {
            width: 140px;
            height: 44px;
            font-weight: 700;
            font-size: 13px;
            // color: #ffffff;
            letter-spacing: 1px;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.create-new-page {
    background: #ffffff;
    box-shadow: 0 10px 23px 0 rgba(47, 52, 78, 0.08);
    border-radius: 10px;
    padding: 20px;

    @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        border-radius: 0;
        padding: 20px 5px;
    }

    &__row {
        display: flex;
        margin: 0 -10px;
        width: calc(100% + 20px);

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    &__left {
        padding: 0 10px;
        width: 58%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__right {
        padding: 0 10px;
        width: 42%;

        @media (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
        }
    }
}

.new-page {
    background: #ffffff;
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    padding: 20px;

    &__elem {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__ttl {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
    }
}

.widget-info {
    background: #f8f8f8;
    border-radius: 10px;
    min-height: 100%;
    padding: 20px;

    &__ttl {
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        line-height: 24px;
        margin-bottom: 20px;
    }

    &__box {
        background: #ffffff;
        border: 1px solid #dbe7ea;
        border-radius: 10px;
        padding: 20px;
    }

    &__name {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        line-height: 20px;
        margin-bottom: 12px;
    }

    &__elem {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.jodit-workplace {
    ol {
        padding-left: 20px;
        list-style-type: decimal;
    }

    ul {
        padding-left: 20px;
        list-style-type: disc;
    }
}

.comment-box {
    background: #ffffff;
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    padding: 15px;

    &__header {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
    }

    &__ava {
        margin-right: 12px;

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }
    }

    &__date {
        font-size: 12px;
        color: #9ba2a9;
    }

    &__actions {
        margin-top: 12px;

        .ui.button {
            height: 30px;
            width: 118px;
            padding: 0;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 14px;
        color: #2a2f34;
        margin-right: 12px;
    }

    &__text {
        font-size: 14px;
        color: #2c3647;
        letter-spacing: 0;
        line-height: 20px;
    }

    &__quote {
        position: relative;
        font-size: 14px;
        color: #2c3647;
        letter-spacing: 0;
        line-height: 20px;
        padding-left: 13px;
        position: relative;
        margin-bottom: 15px;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            left: 0;
            width: 2px;
            background: $color-blue;
        }
    }

    &__action {
        margin-left: 10px;

        >.icon {
            cursor: pointer;
        }
    }

    &__buttons {
        >.ui.button {
            margin-top: 5px;
            height: unset;
        }
    }
}

.multiple-dropdown.ui.multiple.dropdown {
    >.label {
        background: #a2d7f3;
        color: #fff;
        border: none;
        box-shadow: none;
    }
}

.drug-box {
    background: #ffffff;
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    padding: 20px;

    &__item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        letter-spacing: 0;
        margin-bottom: 8px;
    }
}

.edit-knowledge-block {
    background: #ffffff;
    border: 1px solid #dbe7ea;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    &__show {
        button {
            border: none;
            background: transparent;
            font-weight: 600;
            font-size: 14px;
            color: #2c3647;
            line-height: 20px;
            position: relative;
            padding-right: 22px;

            &::after {
                position: absolute;
                content: "";
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 7px;
                width: 12px;
                background: url("../images/ico-arrow-toogle.svg") center / contain no-repeat;
            }
        }

        &.active {
            button {
                font-size: 0px;

                &::after {
                    transform: translateY(-100%) rotate(180deg);
                }
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__body {
        margin-top: 30px;

        ul {
            list-style-type: none;
        }
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        letter-spacing: 0;
    }

    &__elem {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 10px;
        }
    }

    &__elemreply {
        margin-bottom: 10px;
        margin-left: 30px;


        &:last-child {
            margin-bottom: 10px;
        }
    }


    &__sub-ttl {
        font-weight: 600;
        font-size: 14px;
        color: #2c3647;
        letter-spacing: 0;
        margin-bottom: 12px;
        margin-top: 15px;
    }

    &__comment {
        .ui.button {
            width: 90px;
            height: 30px;
            margin-top: 10px;
            padding: 0;
        }

        .ui.button.long {
            width: 145px;
        }
    }

    &__commentreply {
        .ui.button {
            width: 90px;
            height: 30px;
            margin-top: 10px;
            padding: 0;
        }

        margin-left: 30px;
        margin-top: 20px;
        width: 96%;
    }
}

.references-list {
    &__item {
        display: flex;
        align-items: center;
        word-wrap: break-word;
        margin-top: 5px;
    }

    &__name {
        padding-left: 10px;
        width: calc(100% - 80px);
    }

    &__input {
        // padding-left: 10px;
        width: 100%;
    }

    &__id {
        display: flex;
        align-items: center;
        padding-left: 12px;

        .ui.input>input {
            width: 115px;
            margin-left: 12px;
        }

        .ui.button {
            background: transparent;
            padding: 0;
            margin-left: 12px;
        }
    }

    &__action {
        display: flex;
        justify-content: flex-end;
        width: 80px;

        .ui.button {
            padding: 0;
            background: transparent;

            &:last-child {
                margin-left: 10px;
            }
        }

        i.icon {
            width: auto;
        }
    }
}

.publish-modal {
    width: 100%;
    max-width: 680px;
    margin: auto;

    &__wrap {
        padding: 30px;
    }

    &__ttl {
        font-weight: 600;
        font-size: 18px;
        color: #2c3647;
        margin-bottom: 28px;
    }

    &__text {
        font-size: 16px;
        color: #2c3647;
        line-height: 20px;
        margin-bottom: 60px;
    }

    &__footer {
        display: flex;

        .ui.button {
            width: 120px;
            height: 44px;
        }
    }
}

.react-datepicker .react-datepicker-container .react-datepicker-top {
    background: #a2d7f3;
    border-color: #a2d7f3;
}

.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.current {
    background: rgb(237, 255, 255);
}

.code-highlight {
    background: #f6f6f6;
    border: 1px solid #d7d9dd;
    border-radius: 4px;
    margin-bottom: 60px;

    &__header {
        height: 60px;
        padding-left: 25px;
        font-weight: 600;
        font-size: 16px;
        color: #2c3647;
        background: #f1f1f1;
        border-bottom: 1px solid #d7d9dd;
        align-items: center;
        display: flex;
    }

    &__body {
        padding: 40px 52px;

        @media (max-width: 767px) {
            padding: 15px;
        }

        .code {
            font-size: 16px;
            color: #2c3647;
            letter-spacing: 0;
            line-height: 24px;

            .hljs-string {
                color: #2dc9c6;
            }
        }
    }
}

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

// .ck.ck-content ol,
// .ck.ck-content ol li {
//     list-style-type: decimal;
// }

.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

.element-error-alert,
.ui.error.form>textarea {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
}

.footer-layout-container {
    display: flex;
    border-radius: 10px;
    margin-bottom: 10px;

    &-active {
        @extend .footer-layout-container;
        background-color: #39933e;
    }

    >.cell {
        min-height: 100px;
        background: #f3f3f3;
        margin: 3px;
        display: grid;
        align-items: center;
        text-align: center;

        &:nth-child(1) {
            background: transparent;
            padding: 10px;
        }
    }

    >.cell_logo {
        width: 10%;
    }

    >.cell_text {
        width: 50%;
        padding: 10px;
    }
}

.table-knowledge__td_d h1 {
    font-size: 16px;
    font-weight: normal;
}

.table-knowledge__td h1 {
    font-size: 16px;
    font-weight: normal;
}

.footer-file-upload {
    position: relative;



    >.input {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: block;
        opacity: 0;
        z-index: 1;
        width: 100%;
        cursor: pointer;
    }

    &-img {
        flex-shrink: 0;
        display: inline-flex;
        max-width: 125px;
        min-width: 50%;
        min-height: 50px;
        max-height: 80px;
        border-radius: 5%;
        border: 1px solid #d9d9d9;
        background: url("../images/ico-photo.svg") center no-repeat;
        align-items: center;
        position: relative;

        >img {
            max-width: 125px;
            max-height: 80px;
            width: 100%;
            padding: 2px;
        }

        &-remove {
            cursor: pointer;
            z-index: 2;
            width: 25px;
            height: 25px;
            background: white;
            position: absolute;
            top: -10px;
            right: -10px;
            border: 1px solid #cdc8c8;
            border-radius: 15px;
            display: flex;
            align-content: space-around;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            align-items: stretch;
            justify-content: center;

            >.icon {
                margin: 0 !important;
            }
        }

        &-link {
            @extend .footer-file-upload-img-remove;
            top: unset;
            bottom: -10px;
        }
    }
}

.drugs-file-upload {
    position: relative;
    float: left;
    width: 100px;



    >.input {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: block;
        opacity: 0;
        z-index: 1;
        width: 100%;
        cursor: pointer;
    }

    &__img {
        flex-shrink: 0;
        display: block;
        font-size: 0;
        width: 50px;
        height: 50px;
        border: 1px dashed #dbe7ea;
        background: url("../images/upload-file.svg") center no-repeat;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}

.ui.button.import {
    padding: 2px;
}


.ui.selection.upward.dropdown.adminCustomDDL {
    width: 220px;
}

.ui.selection.dropdown.adminCustomDDL {
    width: 220px;
}

.ui.selection.dropdown.customDD {
    width: 140px;
    float: right;
    margin-left: 5px;
    margin-right: -83px;
    border: none;
    box-shadow: none;
    min-width: 10em;
    
    // @media (max-width: 1024px) {
        margin-right: 5px;
    // }
}

.ui.selection.dropdown.customDD>.menu.transition>.item>.ui.label {
    background: transparent;
    width: 85px;
}

.ui.selection.dropdown.customDD>.menu {
    @media (max-width: 767px) {
        max-height: 14rem;
    }
}

.ui.selection.dropdown.customDD>.text {
    float: right;
    margin-right: 15px;
}

.ddLabel {
    margin-right: 10px;
    margin-left: auto;
    margin-top: 11px;
    font-weight: bold;
}

.ui.selection.active.dropdown.customDD,
.ui.selection.active.dropdown.customDD .menu {
    border: none;
    width: 225px;
    text-align: right;
}


.ui.default.dropdown.customDD:not(.button)>.text,
.ui.dropdown.customDD:not(.button)>.default.text {
    color: black;
    font-weight: bold;
}

.ui.selection.dropdown.footer_ddl {
    margin-bottom: 10px;
    width: 220px;
}

.createwebpage_back_button {
    flex-shrink: 0;
    display: block;
    font-size: 0;
    width: 50px;
    height: 50px;
    background: url("../images/back-button.svg") center no-repeat;
    cursor: pointer;
}

.createpage {
    float: left;
}

.createpageImg {
    margin-left: auto;
    overflow: hidden;
    margin-top: -6px;
    border: none;
}

.reply {
    color: #79c3ea;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}

.reply.inner {
    margin-left: 30px !important;
}

.resolved {
    background: #e8e8e8;
}

.error {
    color: red;
}

.csCheckBox {
    appearance: auto;
    margin-right: 10px;
}

.img-qr-code {
    width: 150px;
}

.margin-left10 {
    margin-left: 10px;
}